import React, { useContext } from 'react';
import AppContext from "../utils/app-context";
import { withStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import theme from "../styles/theme";
import { useHistory } from "react-router-dom";
import assets from "../utils/assets"
import firebase, {analytics} from "../utils/firebase.js";


const LAUNCHED = true;

const styles = {
    root: {
        width: '100vw',
        height: '100vh',
        backgroundImage: `url(${assets['banner.jpg']})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
    },
    homeHeader: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    logoName: {
    },
    logoNameImage: {
        marginTop: '10px',
        width: '20vw',
    },
    cornerCTAButton: {
        color: '#333333',
        borderColor: '#333333',
        backgroundColor: ' rgba(250, 250, 250, 0.90)'
    },
    homeContainer: {
        width: '100vw',
        height: '100vh',
        maxWidth: '1000px',
        display: 'flex',
        flexFlow: 'column nowrap',
    },
    homeHero: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        color: '#FFFFFF',
        height: '100%',
    },
    heroCTAButton: {
        color: '#FFFFFF',
        backgroundColor: theme.palette.primary.main,
        marginTop: '20px',
        marginBottom: '10px',
        borderRadius: '30px',
        fontSize: '2em',
    },
    heroCTAButton2: {
        color: '#FFFFFF',
        backgroundColor: theme.palette.danger.main,
        marginTop: '10px',
        borderRadius: '30px',
        fontSize: '2em',
    },
    heroCTAButtonMobile: {
        color: '#FFFFFF',
        backgroundColor: theme.palette.primary.main,
        marginTop: '20px',
        marginBottom: '10px',
        borderRadius: '30px',
        fontSize: '1.5em',
    },
    heroCTAButtonMobile2: {
        color: '#FFFFFF',
        backgroundColor: theme.palette.danger.main,
        marginTop: '10px',
        borderRadius: '30px',
        fontSize: '1.5em',
    },
    textHeroMain: {
        color: "#000000",
        fontSize: '4em',
        marginTop: '15vh',
    },
    textHeroSub: {
        color: "#000000",
        fontSize: '2em',
        marginRight: '10px',
    },
    textORSub: {
        fontSize: '2em',
        marginRight: '10px',
        marginLeft: '30px'
    },
    heroSubBox: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    logoNameImageMobile: {
        width: '40vw',
    },
    homeHeroMobile: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        color: '#FFFFFF',
        height: '100%',
    },
    textHeroMainMobile: {
        color: "#000000",
        fontSize: '10vw',
        marginTop: '15vh',
    },
    textHeroSubMobile: {
        color: "#000000",
        fontSize: '5vw',
        marginLeft: '5px',
        marginRight: '5px',
    },
    textORSubMobile: {
        fontSize: '5vw',
        marginLeft: '5px',
        marginRight: '5px',
    },
    heroSubBoxMobile: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        alignItems: 'center'
    },
}


export default function Home(props) {
    const classes = styles;

    // const { currentUser } = useContext(AuthContext);

    const { state } = useContext(AppContext);

    const history = useHistory();

    const handleGetStarted = () => {
        analytics.logEvent('BUTTON_START');
        history.push('/p/zela_bday_info');
    }

    const handleEarlyAccess = () => {
        history.push('/recorder');
    }

    const handleAbout = () => {
        analytics.logEvent('BUTTON_ABOUT');
        analytics.logEvent('BUTTON_START'); // given that this landing page CTA also progresses to project intructions, we also log "BUTTON_START" for our funnel stats
        history.push('/p/zela_bday_info');
    }

    // const handleGift = () => {
    //     history.push('/gift');
    // }

    const cornerCTA = <Button variant="outlined" size="large" className={classes.cornerCTAButton} onClick={handleAbout}>About</Button>

    const mainCTA = (LAUNCHED) ?
        (<>
            <Button variant="contained" size="large" className={classes.heroCTAButton} onClick={handleGetStarted}>Start</Button>
            {/* <Typography className={state.app.isMobile ? classes.textORMobile : classes.textORSub}> OR </Typography>
            <Button variant="contained" size="large" className={state.app.isMobile ? classes.heroCTAButtonMobile2 : classes.heroCTAButton2} onClick={handleGift}>Give the gift of Moments</Button> */}
        </>)
        : (<>
            <Button variant="contained" size="large" className={state.app.isMobile ? classes.heroCTAButtonMobile : classes.heroCTAButton} onClick={handleEarlyAccess}>Request Early Access</Button>
            {/* <Typography className={state.app.isMobile ? classes.textORMobile : classes.textORSub}> OR </Typography>
            <Button variant="contained" size="large" className={state.app.isMobile ? classes.heroCTAButtonMobile2 : classes.heroCTAButton2} onClick={handleGift}>Give the gift of Moments</Button> */}
        </>)

    return (
        <div className={classes.root}>
            <Container className={classes.homeContainer}>
                <div className={classes.homeHeader}>
                    <div className={classes.logoName}>
                        <img src={assets['logo_vert.png']} alt="Okapi video" className={state.app.isMobile ? classes.logoNameImageMobile : classes.logoNameImage} />
                    </div>
                    <div className={classes.cornerCTA}>
                        {cornerCTA}
                    </div>

                </div>
                <div className={state.app.isMobile ? classes.homeHeroMobile : classes.homeHero}>
                    <Typography className={state.app.isMobile ? classes.textHeroMainMobile : classes.textHeroMain}>Zela's birthday video</Typography>
                    <div className={state.app.isMobile ? classes.heroSubBoxMobile : classes.heroSubBox}>
                        <Typography className={state.app.isMobile ? classes.textHeroSubMobile : classes.textHeroSub}>Show us your dance moves. </Typography>
                        <Typography className={state.app.isMobile ? classes.textHeroSubMobile : classes.textHeroSub}>We'll remix the video. </Typography>
                    </div>
                    {mainCTA}


                </div>
            </Container>
        </div>
    )
}