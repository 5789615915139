import React from 'react';
import { Button, Menu, Icon, Link, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';

import { withStyles } from '@mui/styles';
import {useLocation} from "react-router";
import theme from '../styles/theme'

// import { useCopyToClipboard } from "react-use";
import { useQRCode } from 'next-qrcode'; // https://github.com/Bunlong/next-qrcode/tree/v2.0.0
import config from '../utils/config'

const styles = {
    url_select: {
        borderRadius: '30px',
        fontSize: '1em',
        justifyContent: 'center',
        forceSelect: {  
          WebkitUserSelect: 'all',  /* Chrome 49+ */
          MozUserSelect: 'all',     /* Firefox 43+ */
          MsUserSelect: 'all',      /* No support yet */
          UserSelect: "all",        /* Likely future */   
        }
    },
    modalTitle: {
      paddingBottom: '2',
      '& h2': {
        display: 'flex',
        justifyContent: 'space-between', 
      }
    },
    modalBody: {
      textAlign: 'center'
    },
    qrCode: {
      border: '0px solid #eee',
      marginBottom: '10px'
    }
}


const useCopyToClipboard = (text) => {
  const copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    // console.log("pre-copy", str)
    // console.log("document", document)
    // console.log("selected", selected)
    // console.log("el", el.value)

    const success = document.execCommand('copy');
    //console.log("post-copy success?", success)

    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
    return success;
  };

  const [copied, setCopied] = React.useState(false);

  const copy = React.useCallback(() => {
    console.log("tried copy. is copied: ", copied )
    if (!copied) setCopied(copyToClipboard(text));
  }, [text]);
  React.useEffect(() => () => setCopied(false), [text]);

  return [copied, copy];
};


const ShareDialog = (props) => {
  const classes = styles
  //const [open, setOpen] = React.useState(false);
  const app_url = config.app_url
  const loc = useLocation();  

  const { open, toggleShare } = props

  const link = app_url + loc.pathname
  // const link = app_url + "/p/" + props.portal_id

  const [project_link_copied, copy] = useCopyToClipboard(link); // test from here ***
  const [showSuccess, setShowSuccess] = React.useState(false)

  const { Canvas } = useQRCode();

  const [copied, copyToClipboard] = useCopyToClipboard('ddd');

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    toggleShare(false)
  };

  const successStyles = {
    position:'absolute', 
    bottom:'-20px', 
    left:'0px', 
    fontSize:'9px', 
    lineHeight:'12px', 
    textTransform:'lowercase', 
    transition:'.5s all'
    /*background:theme.palette.secondary.main, 
    padding:'3px 10px',*/
  }

  const copyWrapper = {
    position:'relative'
  }

  function copyLink(text) {
    navigator.clipboard.writeText(text);
    setShowSuccess(true);
    const timer = setTimeout(() => {
      setShowSuccess(false);
    }, 1500);
  }

  /*const handleYes = () => {
    setOpen(false); 
  };*/


  /*const handleCopy = () => {
    copyToClipboard("1234");
   };*/



  //  note:  there is a working example here:   http://localhost:3000/zela_bday_info
  const attempt3 = <div className="{classes.url_select}">
              <Typography className={classes.url_select}> {link} </Typography>
              <button onClick={copy}> Copy </button>
              <span>{project_link_copied && ' Link copied!'}</span>  
          </div>

  const alternate_backup_link =  link

  const linkStyles = {
    cursor:'pointer', 
  }
  {/*<div className="{classes.url_select}">
              <Typography className={classes.url_select}>  </Typography>
  </div>*/}

  /*const old_button =  <Button variant="outlined" visible={false} onClick={handleClickOpen}>
        {props.name}
      </Button> // replaced with menu item*/

  const truncateText = (text, length) => {
    if (text.length <= length) return text;
    return text.substring(0, length) + '....';
  };


  return <div>
      <Dialog
        open={open}
        sx={{ '& .MuiDialog-paper': { width: {
          xs: '320px',
          sm: '360px',
          md: '340px',
          lg: '340px',
          xl: '340px',
        }, maxWidth: 'none' } }}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.modalTitle}> <span>Share</span> <Icon name='close' onClick={handleClose}/></DialogTitle>
        <DialogContent className={classes.modalBody} sx={{ maxWidth:480 }}>
          <DialogContentText id="alert-dialog-description">
              Anyone with this link can upload video.
          </DialogContentText>

          <Box className={classes.qrCode} sx={{ margin:'0 auto', width:'280px' }}>
            <Canvas
              text={link}
              options={{
                type: 'image/jpeg',
                quality: 0.3,
                level: 'H',
                margin: 3,
                scale: 4,
                width: 280,
              }}
            />
          </Box>

        <DialogContentText id="alert-dialog-description" sx={ copyWrapper }>
        {alternate_backup_link && 
          <Link sx={ linkStyles } onClick={ () => { copyLink(alternate_backup_link) } }>
            {truncateText(alternate_backup_link, 30)} 
            <ContentCopyIcon sx={{ position:'relative', bottom:'-6px' }} />
          </Link>
        }
          {showSuccess && <Box className="copy-success" sx={ successStyles }>Link Copied!</Box> }
          {link=="this is not working yet" && attempt3}
        
        </DialogContentText>



        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
}

export default ShareDialog