import React, { useContext, useState, Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../utils/app-context";
import "../components/pmm.css"
import RecordableShotGroup from "../components/recordable-shot-group";
import AlertUploadStatus from './alert-upload-status';
import ViewToggle from "../components/toggle-view";
import { Tab, Link, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Typography, ToggleButton, Icon, Menu, Container, Box, Button, MenuItem, formHelperTextClasses } from '@mui/material';
import { TabList, TabContext, TabPanel } from '@mui/lab';
import Grid from '@mui/material/Unstable_Grid2'
import Item from '@mui/material/Unstable_Grid2'
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';

import { v4 as uuidv4 } from 'uuid';
import firebase from '../utils/firebase';

import Headbar from "./Headbar";
import Footbar from "./Footbar";
import theme from '../styles/theme'


import ZebraPlayer from "../components/zebra-player"; 
import ReactPlayer from "react-player"
import {computeSequence, computePercentFilmed} from '../utils/sequence.js';

import navLogo from '../images/nav-logo.svg'
import { css } from '@emotion/css'
import { withStyles } from '@mui/styles';

function Preview(props) {
    const { state, dispatch } = useContext(AppContext);
    const history = useHistory();  
    
    const [displayShare, setDisplayShare] = useState(true)
    const [tabvalue, setTabValue] = React.useState(0);

    // having a local state caused problems b/c it would not update if there was a change with user 
    // (isOwner has a delayed update and determines if you start out looking at LIVE or a view defined by settings)
    //
    //      const [prioritizeLivePreview, setPrioritizeLivePreview] = React.useState(state.app.prioritizeLivePreview);
    //
    // now we use state.app.prioritizeLivePreview

    const story = state.stories.activeStory //state.story //state.stories.activeStory
    const shots = state.stories.activeShotList
    const takes = state.stories.activeTakeList // this is all takes in the story

    const portal_id = (state.stories.activeStory.portal_id) ? state.stories.activeStory.portal_id : state.app.portal.id
    const fixed = false

    function doInfo() {
        const msg = "Help us collect footage to make a video that follows this storyboard. Tap the image in any planned shot to upload a new take 1-6 seconds long. We prefer horizontal videos (cell phone sideways).  Thank you!"
        const msg2 = msg + `\n\n id: ${state.app.portal.id}`
        alert(msg)
      }

    const contStyles = {
      padding:'1em 2em',
      minHeight:'30vh', 
      [theme.breakpoints.down('md')]: {
        padding:'0 1em',
      }
    }

    const wrapStyles = {
      padding:"3em", 
      display:"flex", 
      justifyContent:'center', 
      '&.export-wrap': {
        alignItems:'center', 
        '.preview': {
          flex:'2 1 0', 
          margin:0, 
          height:'auto'
        }, 
        '.export': {
          alignItems:'flex-start', 
          paddingLeft:'2em'
        }
      }, 
      [theme.breakpoints.down('md')]: {
        padding:'1.5em', 
        '&.export-wrap': {
          flexDirection:'column', 
          '.preview': {
            order:1
          }, 
          '.export': {
            order:2, 
            padding:0, 
          }, 
          '.buttons': {
            textAlign:'center'
          }
        }
      }
    }

    const previewStyles = {
      maxWidth:'864px', 
      maxHeight:'466px', 
      margin:'0 auto', 
      textAlign:'center', 
      width:'100%', 
      height:'466px', 
      background:theme.palette.secondary.contrastText, 
      'video': {
        width:'100%'
      }, 
      '.empty': {
        height:'100%', 
        display:'flex', 
        alignItems:'center', 
        justifyContent:'center'
      }
    }

    const panelStyles = {
      minHeight:'100px', 
      marginBottom:'2em', 
      justifyContent:'flex-start', 
      [theme.breakpoints.down('md')]: {
        minHeight:'0'
      }
    }


    const sequence = computeSequence(story, shots, takes)
    const filmed = computePercentFilmed(story, shots, takes)

    function setSelectedShotAndAction(shot, action) {
      setSelectedShot(shot)
      setSelectedShotAction(action)
    }

    const pct_filmed_str = filmed['pct_str']
    const last_cut_published = true     // move to portal.last_cut_published? or story.last_cut_published?
    const live_view_pct_threshold = 0 // move to portal.live_view_pct_threshold?
    // const live_draft_published = true // move to portal.live_draft_published?

    const show_last_cut = (story.last_cut && story.last_cut.stream_path) ? true : false //last_cut_published && 'last_cut' in story && story.last_cut !== null
    const show_live_preview = (filmed['frac'] > live_view_pct_threshold) && 'url' in sequence
    const goCancel = () => {
      if(portal_id) history.push("/p/"+portal_id)
      else history.push("/storyboards")
    }    

    const cancelBtn = {click: goCancel, type:"tertiary", text:"Back"}


    if(!tabvalue) {
      setTabValue("live")
    }
    const tabChange = (event, newValue) => {
      // setPrioritizeLivePreview(!prioritizeLivePreview)

      //dispatch({ type: "UPDATE_PRIORITIZE_LIVE_PREVIEW", payload: { prioritizeLivePreview:  !state.app.prioritizeLivePreview} });
      setTabValue(newValue);
    };

    function saveThisCut() {

      const storyRef = state.stories.activeStory.ref

      if (storyRef) {
      
          // settings for render
          const do_low_res = true
          const do_high_res = true

          // makes a zip archive at every save  ... its a bit slow, but we can render an export button when its ready   ... or we could do a popup alert message, only after a desired export action?
          const do_export = true  

          // use tgt render if possible
          //console.log('tgt_video_filenames:', sequence['tgt'])
          //console.log('tgt_video_filenames length:', sequence['files'].length, sequence['tgt'].length)
          const has_all_tgts_defined = sequence['tgt'] && !sequence['tgt'].includes(undefined)
          //console.log('has_all_tgts_defined:', has_all_tgts_defined)
          const do_tgt_cut = has_all_tgts_defined && (sequence['files'].length === sequence['tgt'].length)

          // ToDo?:  move creation (eg. cutRef.set) into AppContext via dispatch. then append 'cut.requester' = currentUser

          if (do_tgt_cut)    
            alert("Saving a cut trimmed to match the audio. How long will it take? A low res version takes about a minute and a higher resolution will follow.")
          else  {
            alert("Saving a cut of all the selected takes. How long will it take? A low res version takes about a minute and a higher resolution will follow.")
          }
          
          const tgt_audio = story.last_audio ? story.last_audio : null // 'media/tgt/manual/sizzle-640-360.m4v'. // consider setting to 'None' in some cases where audio won't align.  clips not in order, or empty shots, or short shots?

          if (do_tgt_cut) {
            
            if (do_low_res) {
                console.log('LOW RES tgt RENDER video_filenames:', sequence['proxy'])
                const cut_request_tgt = {save_type: "tgt",  
                    high_quality:"False",
                    video_filenames: sequence['proxy'], 
                    audio: tgt_audio, // todo: get from import_media if it has audio? place here if there is only one chapter with clips in order?  story.last_audio
                    version: null,
                    stream_path: null,
                    thumbnail: null,
                    created_at: firebase.firestore.FieldValue.serverTimestamp(),
                    completed_at: null,
                    tgt:{'media': sequence['tgt']}
                  };
                const cut_id_tgt = uuidv4()
                const cutRefTgt = storyRef.collection('cuts').doc(cut_id_tgt) 
                cutRefTgt.set(cut_request_tgt)
                     .then(() => {
                       dispatch({type: "START_CUT", payload: {}});
                     })
                 .catch((error) => {
                     console.log('Error creating HQ cut request:', cut_request_tgt)
                })
                console.log('tried_cut tgt merge:', cut_request_tgt)
            }

            if (do_high_res) {
                //console.log('HIGH RES tgt RENDER video_filenames:', sequence['files'])
                //console.dir(sequence)

                const cut_request_tgt = {save_type: "tgt",  
                  high_quality:"True",
                  video_filenames: sequence['files'], 
                  audio: tgt_audio, 
                  version: null,
                  stream_path: null,
                  thumbnail: null,
                  created_at: firebase.firestore.FieldValue.serverTimestamp(),
                  completed_at: null,
                  tgt:{'media': sequence['tgt']}
                };
                const cut_id_tgt = uuidv4()
                const cutRefTgt = storyRef.collection('cuts').doc(cut_id_tgt) 
                cutRefTgt.set(cut_request_tgt)
                     .then(() => {
                       dispatch({type: "START_CUT", payload: {}});
                     })
                 .catch((error) => {
                     console.log('Error creating HQ cut request:', cut_request_tgt)
                 })
                 console.log('tried_cut tgt merge:', cut_request_tgt)

                if (do_export) {
                      const export_request_tgt = {
                        video_filenames: sequence['files'], 
                        audio: tgt_audio, 
                        version: null,
                        dst_zip_url: null,
                        created_at: firebase.firestore.FieldValue.serverTimestamp(),
                        tgt: {'media': sequence['tgt']},
                      };
                      const export_id_tgt = cut_id_tgt;  // uuidv4()
                      const exportRefTgt = storyRef.collection('exports').doc(export_id_tgt) 
                      exportRefTgt.set(export_request_tgt)
                           .then(() => {
                             dispatch({type: "START_EXPORT", payload: {}});
                           })
                       .catch((error) => {
                           console.log('Error creating export request:', export_request_tgt)
                       })
                       console.log('tried export_request_tgt:', export_request_tgt)
                }

              }



          } else { // non tgt cut is a render
            if (do_low_res) {
                console.log('LOW RES RENDER video_filenames:', sequence['proxy'])
                const cut_id = uuidv4()
                const cut_request = {save_type: "basic",  
                              high_quality:"False",
                              video_filenames: sequence['proxy'], 
                              audio: 'silent',  // todo:  do we want this to be "silent"?
                              version: null,
                              stream_path: null,
                              thumbnail: null,
                              created_at: firebase.firestore.FieldValue.serverTimestamp(),
                              completed_at: null};

                const cutRef = storyRef.collection('cuts').doc(cut_id)
                cutRef.set(cut_request)
                    .then(() => {
                      dispatch({type: "START_CUT", payload: {}});
                    })
                .catch((error) => {
                    console.log('Error creating cut request:', cut_request)
                })
                console.log('tried_cut:', cut_request)
            }

            if (do_high_res) {
                console.log('HIGH RES RENDER video_filenames:', sequence['files'])
                // note: handling of video_path is not addressed for tgt videos (e.g. tgt_stream_video exist in low res, not hgh)
                //   could:  backfill low res mized with high, or propogate a link to a private high res file

                const cut_reques_hq = {save_type: "basic",  
                    high_quality:"True",
                    video_filenames: sequence['files'], 
                    audio: 'silent',
                    version: null,
                    stream_path: null,
                    thumbnail: null,
                    created_at: firebase.firestore.FieldValue.serverTimestamp(),
                    completed_at: null};

                const cut_id_hq = uuidv4()
                const cutRefHq = storyRef.collection('cuts').doc(cut_id_hq) 
                cutRefHq.set(cut_reques_hq)
                     .then(() => {
                       dispatch({type: "START_CUT", payload: {}});
                     })
                 .catch((error) => {
                     console.log('Error creating HQ cut request:', cut_reques_hq)
                 })
                 console.log('tried_cut HQ:', cut_reques_hq)

                  if (do_export) {
                    console.log('export video_filenames:', sequence['files'])
                    const export_request = {
                      video_filenames: sequence['files'], 
                      audio: tgt_audio, // null  ... note:  we are allowing the audio from tgt to come out in export, even if trimming media is not complete ...
                      version: null,
                      dst_zip_url: null,
                      created_at: firebase.firestore.FieldValue.serverTimestamp(),
                      tgt: null,
                    };
                    const export_id = cut_id_hq;  // uuidv4()
                    const exportRefTgt = storyRef.collection('exports').doc(export_id) 
                    exportRefTgt.set(export_request)
                         .then(() => {
                           dispatch({type: "START_EXPORT", payload: {}});
                         })
                     .catch((error) => {
                         console.log('Error creating export request:', export_request)
                     })
                     console.log('tried export_request (non_tgt):', export_request)
                  }
            }

          }
  
          dispatch({ type: "CLEAR_LAST_CUT", payload: {} });
          dispatch({ type: "CLEAR_LAST_EXPORT", payload: {} });


      } else {
          console.log('no STORY, failed to request a cut')
      }
    }
    const communityCanToggleLivePreview = state.app.settings.communityCanToggleLivePreview /*&& show_last_cut && show_live_preview*/
    //const communityLiveButtonText = tabvalue == 'live' /*state.app.prioritizeLivePreview*/ ? "Live" : "Saved"

    return( 
      <Container className="has-footbar" style={{ maxWidth:"100%", paddingLeft:0, paddingRight:0 }}>

        <Headbar title={story.title ? story.title : (state.app.userIsStoryOwner) ? "Untitled" : "Collaborative Video"} share={true} fork={true} />
        <Box sx={ wrapStyles } className={ (state.app.userIsStoryOwner) ? 'export-wrap' : 'preview-wrap' }>
          <Box sx={ previewStyles } className="preview">

            {/*state.app.userIsStoryOwner && */tabvalue == 'live' && show_live_preview && sequence['url'] && <div className="live-preview">
                  {<ZebraPlayer video_urls={sequence['url']} light={false} controls={false} playing={true} loop={false} sx={{ width:'100%' }} />}
            </div> }

            {/*state.app.userIsStoryOwner && */tabvalue == 'save' && show_last_cut && <div className="last-cut">
                  <ReactPlayer
                        className='react_player'
                        url={story.last_cut.stream_path}
                        controls={true}
                        allowFullScreen={false}
                        light={false}
                        volume={state.app.settings.savedPreviewHasAudio}
                        width='100%'
                        height='100%'
                  />
            </div> }


            {/*!state.app.userIsStoryOwner && !state.app.prioritizeLivePreview && show_last_cut && <div className="last-cut">
                  <ReactPlayer
                        className='react_player'
                        url={story.last_cut.stream_path}
                        controls={true}
                        allowFullScreen={false}
                        light={false}
                        volume={state.app.settings.savedPreviewHasAudio}
                        width='100%'
                        height='100%'
                  />
    </div> */}
            {!state.app.userIsStoryOwner && communityCanToggleLivePreview && 
              <Box className="community-toggle" sx={{ display:'flex', padding:'4em', marginBottom:'7em', justifyContent:'center' }} > 
               <Button variant={tabvalue == 'live' ? "contained" : "outlined"} onClick={(e) => { tabChange(e, "live") }}> Live 
               </Button>
               <Button disabed={(story.last_cut == undefined || story.last_cut.stream_path == undefined) ? true : false } variant={tabvalue == 'save' ? "contained" : "outlined"} onClick={(e) => { tabChange(e, "save") }}> Saved 
               </Button>
              </Box>}

            {(!show_last_cut && !show_live_preview) && 
                  <Box className="empty" sx={{ marginBottom:'4em' }}>
                    <Typography variant="body1">
                      Preview will be visible when media is added.
                    </Typography>
                  </Box> }



            </Box>
          {state.app.userIsStoryOwner && <Box className="export" sx={{ width:'300px' }}>
              <TabContext value={tabvalue} aria-label="basic tabs">
                <TabList onChange={tabChange}>
                  <Tab label="Live Preview" value="live" />
                  <Tab label="Latest Save" value="save" disabled={!show_last_cut} />
                </TabList>
                <TabPanel value="live">
                  <Box sx={ panelStyles }>
                    <Box component="p"><Typography variant="body1">Now viewing the latest cut of your selected takes.</Typography></Box>
                    <Box component="p"><Typography variant="body1">Save the cut to view an exportable high quality preview.</Typography></Box>
                  </Box>
                  <Box className="buttons">
                    <Button variant="contained" disabled={!takes.length || filmed.count == 0} onClick={ saveThisCut }>Save Cut</Button>
                  </Box>
                </TabPanel>
                <TabPanel value="save">
                  <Box sx={ panelStyles }>
                    <Typography variant="p">Now viewing the latest save.</Typography>
                  </Box>
                  <ToggleButton
                    value="check"
                    //selected={movable}
                    onChange={() => {}}
                    sx={{ color:theme.palette.primary.contrastText, border:0, justifyContent:'left', padding:0, marginBottom:'10px' }}
                  >
                    {!true && <>Option 1</>}
                    {true && <>Option 2</>}
                  </ToggleButton>
                  <Box className="buttons" sx={{ display:'flex', flexDirection:'column' }}>
                    {story.last_cut && <Button
                      icon='video'
                      href={story.last_cut.stream_path}
                      download="video.mp4"
                      target="_blank"
                      variant="contained" color="secondary"
                      sx={{ marginBottom:"2em"}}>Download MP4</Button>
                    }
                    {story.last_export && <Button
                        labelPosition='right'
                        icon='zip'
                        variant="contained"
                        href={story.last_export}
                        download="fcp.zip">Export Archive</Button>
                    }
                  </Box>
                </TabPanel>
            </TabContext>
          </Box>}
        </Box>

        <Footbar buttons={[cancelBtn]} />      

      </Container>
    )
}


export default Preview