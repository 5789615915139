import React, { useContext, useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import firebase from '../utils/firebase';
import AppContext from "../utils/app-context";

// navbar is unique to scene
import Navbar from './Navbar'
import navLogo from '../images/nav-logo.svg'
import { css } from '@emotion/css'

import ShotGroup from "./shot-group"; 
import Breadcrumbs from "./Breadcrumbs";

// import StoryPartForm from "./story-part-form";
import AlertImportStoryboardStatus from './alert-import-storyboard';
import ViewToggle from "./toggle-view";
import SidebarNav from './SidebarNav';

import { nanoid } from "nanoid";
import { v4 as uuidv4 } from 'uuid';
import EditableField from "../components/editable-field";

import theme from '../styles/theme'

import { Tab, Box, Button, ToggleButton, Typography, Icon, Container, RadioGroup, Drawer, Dialog, Link, DialogTitle, DialogContent, DialogActions, Checkbox, Input, InputLabel, Menu, FormControl, OutlinedInput, MenuItem } from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2'
import Item from '@mui/material/Unstable_Grid2'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import GridViewIcon from '@mui/icons-material/GridView';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import VisibilityIcon from '@mui/icons-material/Visibility';
//import SettingsIcon from '@mui/icons-material/Settings';
import ShareIcon from '@mui/icons-material/Share';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/Circle';

import ReactPlayer from "react-player" ///  "react-player/lazy" can we only pre-load the mp4 file version?
import ZebraPlayer from "../components/zebra-player"; 

import Headbar from "./Headbar"
import Footbar from "./Footbar"

import { computeSequence } from '../utils/sequence.js';

const other_shots = [{
    header: 'Andrew Intro',
    description: 'ANDREW is hot after a run. "Ready for a beer?"',
    meta: 'In front of market',
    image: "https://storage.googleapis.com/v333m-clip/tgt/tour10/th/thmb006a.jpg"
  },
  {
    header: 'Walking',
    description: 'Following ANDREW through main entrance.',
    meta: 'Entering market',
    image: "https://storage.googleapis.com/v333m-clip/tgt/tour10/th/thmb001a.jpg"
  },
  {
    header: 'Environment',
    description:'ANDREW is waiting in line.  WIDE shot with market bustle: color, sounds, smells.',
    meta: 'Central Market',
    image: "https://storage.googleapis.com/v333m-clip/tgt/tour10/th/thmb000a.jpg"
  },
    {
    header: 'Butcher Intro',
    description: 'Glance up from carving. "Solo Jamon de Bellota 100%"',
    meta: 'At stall',
    image: "https://storage.googleapis.com/v333m-clip/tgt/tour10/th/thmb004a.jpg",
  },
  {
    header: 'Small talk',
    description: 'ANDREW buys some ham from the BUTCHER.',
    meta: 'At stall',
    image: "https://storage.googleapis.com/v333m-clip/tgt/tour10/th/thmb006a.jpg",
  }
]

function Scene(props) {

    const { state, dispatch } = useContext(AppContext);
    const history = useHistory();

    //const story = state.story

    const story = state.stories.activeStory //state.story //state.stories.activeStory
    const takes = state.stories.activeTakeList // this is all takes in the story
    const shots = state.stories.activeShotList // this is all undeleted shots

    const footbarBtn = {click: startCollecting, type:"primary", icon:"circle", text:"Collect"}
    const [previewValue, setPreviewValue] = React.useState('0');
    const previewChange = (event, newValue) => {
      setPreviewValue(newValue);
    };


    //const num_shots = ('parts' in story && 'order' in story.parts) ? story.parts.reduce((count, part) => count = count + part.order.length, 0) : 0
    // const num_shots =  story.parts.reduce((count, part) => count = count + part.order.length, 0) 
    const num_shots =  shots.length  // state.story.num_shots  // we now try compute this in an Effect in the app, but it has the same errors. Story is present, but lacks parts.
    const cost_per_veneer = 10  // for now, all clips constant.  I imagine this for up to 5 sec clips

    
    const [importUrl, setImportUrl] = useState("https://www.youtube.com/watch?v=0nXB-Bgx1eM");
    const [prompt, setPrompt] = useState("portrait of a cyborg in a cinematic animation style, scene from borderlands");
    const [importFormOpen, setImportFormOpen] = React.useState(false)
    const [previewFormOpen, setPreviewFormOpen] = React.useState(false)
    const [imageSelectorOpen, setImageSelectorOpen] = React.useState(true)
    const [takeSelectorOpen, setTakeSelectorOpen] = React.useState(true)
    const [selectedShot, setSelectedShot] = React.useState(null)
    const [selectedShotAction, setSelectedShotAction] = React.useState(null)
    const [selectedItem, setSelectedItem] = React.useState(null)  // the content that gets applied to shot:  e.g. an image for shot type, or a take (image + video clip)

    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [urlValid, setUrlValid] = React.useState(true)
    const [promptValid, setPromptValid] = React.useState(true)


    const [displayImages, setDisplayImages] = useState(true);
    const [madeEdit, setMadeEdit] = useState(false);
    const [viewLatestTake, setViewLatestTake] = useState(true);

    const fixed = false
    const headNavStyles = {
      padding:'1em', 
      backgroundColor: theme.palette.primary.main, 
      display:'flex', 
      justifyContent:'space-between', 
      position:'relative'
    }
    const subNavStyles = {
      padding:'1em', 
      borderBottom: '1px solid ' + theme.palette.primary.main, 
      display:'flex', 
      position:'relative', 
      marginBottom:'2em', 
      alignItems:'center'
    }
    const sideMenuStyles = {
      backgroundColor:theme.palette.primary.main, 
      '> div': {
        background:theme.palette.primary.main, 
        borderLeft:'1px solid ' + theme.palette.primary.main, 
        padding:'1em', 
        minWidth:'200px'
      }, 
      '.close': {
        marginBottom:'2em'
      }, 
      'a': {
        color:theme.palette.primary.main, 
        display:'flex', 
        textAlign:'left'
      }, 
      'span': {
        padding:0, 
        display:'block', 
        margin:'0 .5em 1em 0', 
      }, 
      'svg': {
        display:'block', 
        marginRight:'.5em'
      }, 
      'input': {
        color:theme.palette.primary.main, 
        padding:0, 
      }
    }

    // strategy for holding nav data below ... prob doesn't belong here.  consider if Layout is used. 
    const [data, setData] = useState('')
    const NavbarData = (navData) => {
      setData(navData)
    }

    function updatePreviewFormOpen(state) {
      if(state == undefined) setPreviewFormOpen(true)
      else setPreviewFormOpen(state)
    }

    function addShot(part_id, description) {

      const random_int = Math.floor((Math.random()* 18))
      const biased_int = [0,0,1,1,1,1,2,2,3,3,5,5,6,6,7,7,8,8,0][random_int]
      const shot_type_filenames = ["wide.png", "mid.png","action.png","close.png","empty.png","xcu.png","two_shot.png","ots.png","full.png", "action_close.png",]
      const shot_type = shot_type_filenames[biased_int]
      const random_image = `https://storage.googleapis.com/v0zebra.appspot.com/resource/shot_type/${shot_type}`

      //const order = story.parts[part_index].order
      //const prev_image = (order.length > 0) ? shots.filter(shot => shot.id===order[order.length - 1])[0].image: " "
      //var prev_meta = (order.length > 0) ? shots.filter(shot => shot.id===order[order.length - 1])[0].meta: " "
      //if (prev_meta === undefined) {
      //  prev_meta = ""
      //}   

      const new_panel_desc = ['Wide shot', 'Mid shot', 'Action shot', 'Close up of face', '', 'Extreme close up', 'Two-Shot','Over the shoulder','Full shot','Action Close up'][biased_int]
      const one_word_title = description.substring(description.lastIndexOf(" ")+1)
      const punctuation_free_title = one_word_title.replace(/[".,\/#!$%\^&\*;:{}=\-_`~()]/g,"")
      const capitalized_title = punctuation_free_title[0].toUpperCase() + punctuation_free_title.substring(1);
      const newShot = {id: nanoid(),
       header: capitalized_title,
       image: random_image,
       meta: "",
       prompt: "",
       description: new_panel_desc,
       last_take_id: null,
       selected_take_id: null, 
       last_take_im: null,
       last_take_stream_url: null, 
       deleted_at: null
     };
       
       const payload = {
        part_id: part_id,
        shot: newShot}
       dispatch({ type: "ADD_STORY_SHOT", payload: payload });

       setMadeEdit(true)
    }

    function toggleDisplayView() {
      setDisplayImages(!displayImages) 
      //console.log(state.app)
      dispatch({type: "IMPORT_SUCCESS", payload: {}});
    }

    function startCollecting(start_with_share_Dialog) {
        //let portal_id = story.portal_id
        let portal_id = state.stories.activeStory.portal_id

        if (!portal_id || portal_id === null){
            portal_id = nanoid(12)
            dispatch({type: "ADD_REQUESTED_PORTAL", payload: {portal_id: portal_id}});
        }
        //pushActiveStoryToCloud()
        //history.push('/collect');
        history.push('/p/' + portal_id);
    }


    function createRandomShots(num_shots) {
      const prev_meta = ' '

      var random_shots = [];
      for (var i = 0; i < num_shots; i++) {
            const random_int = Math.floor((Math.random()* 10))
            const random_image = `https://storage.googleapis.com/v333m-clip/tgt/tour10/th/thmb00${random_int}a.jpg`
            const new_shot = {id: nanoid(),
             header: randomTitle(),
             image: random_image,
             meta: prev_meta,
             description: 'Add description here',
             deleted_at: null};
            random_shots.push(new_shot);
      }
      return random_shots
    }

    function randomTitle() {
      const title_list = ["Give", "Floor", "Bang", "UhOh", "Serious", "Nervous", 
      "Honest", "Chase","Harder", "Grab", "Put", "Smirk", "Away"]
      const title = title_list[Math.floor((Math.random()* title_list.length))]
      return title
    }

    function randomStory() {
      if (madeEdit) {
        alert("All your edits will be replaced with a new random story.")
      }

       const num_shots = Math.ceil(story.target_duration / story.pace)
       const num_start_shots = Math.floor(num_shots * (0.2 + (Math.random() / 5)))
       const num_end_shots = Math.floor(num_shots * (0.2 + (Math.random() / 5)))
       const num_mid_shots = num_shots - (num_start_shots + num_end_shots)

       dispatch({ type: "UPDATE_STORY_SHOTS", payload: {part_index: 0, shots: createRandomShots(num_start_shots)} });
       dispatch({ type: "UPDATE_STORY_SHOTS", payload: {part_index: 1, shots: createRandomShots(num_mid_shots)} });
       dispatch({ type: "UPDATE_STORY_SHOTS", payload: {part_index: 2, shots: createRandomShots(num_end_shots)} });
       setMadeEdit(false)
    }

    function clearSelectedShotAndItem() {
        setSelectedShot(null)
        setSelectedItem(null)
        setSelectedShotAction(null)
    }

    function createVeneerForTake() {
      // console.log("prompt", prompt) //, selectedShot, selectedItem))
      // console.log("ShotId", selectedShot) //, selectedShot, selectedItem))
      // console.log("selectedItem", selectedItem) //, selectedShot, selectedItem))
      createVeneer(prompt, 
        selectedItem.video_path, // the full path w/o the storage bucket
        selectedShot.id, 
        selectedItem.id,
        cost_per_veneer) // cost is fixed at 10.  fps not under control. selectedItem.dur * fps * lookup
      dispatch({ type: "UPDATE_STORY_SHOT_PROMPT", payload: {prompt: prompt, shot_id: selectedShot.id} });
      alert("Request received to recreate your video. There is a queue. Typically, after 30-60 minutes the results will be added to your project.")

      setSelectedShot(null)
      setSelectedItem(null)
      setSelectedShotAction(null)
    }

    function handleShotDelete(shot) {
      const msg = `would delete ${shot.id}`
      console.log(msg)
      const payload = {shot_id: shot.id}
       dispatch({ type: "DELETE_STORY_SHOT", payload: payload });
       // setMadeEdit(true);
    }

    function handleTakeDelete(take, shot) {
      const msg = `would delete ${take.id}`
      console.log(msg)
      const payload = {take_id: take.id,
            shot_id: take.shot_id,
            shot_selected_take_id: shot.selected_take_id,
            shot_last_take_id: shot.last_take_id}
      dispatch({ type: "DELETE_TAKE", payload: payload });
    }

    function runTest() {
      //console.log("STORY", story)
      //console.log("SHOTS", shots)
    }
    //console.dir(story)
    //console.log('----------------------------------------')
    function importStoryboard() {

      setImportFormOpen(false)

      if (!urlValid) return

      const storyRef = state.stories.activeStory.ref
      if (storyRef) {
          const created = new Date()
          const date = created.getHours() + ':' + ((created.getMinutes() < 10) ? '0'+created.getMinutes() : created.getMinutes()) + ' ' + created.toLocaleString('default', { month: 'short' }) + ' ' + created.getDate() + ', `' + created.getFullYear().toString().slice(2)
          const import_req_id = uuidv4() // '1234' // 
          const import_request = {type: "storyboard", 
                        url: importUrl,
                        //"https://www.youtube.com/watch?v=aMlt1I-W5EM", 
                        // amknI7qr25g W86cTIoMv2U", 
                        // aMlt1I-W5EM  7 sec works
                        // https://www.youtube.com/watch?v=qBNmY7S0BG8  12sec stop motion breaks
                        // QpiGPLUCC3w
                        // https://www.youtube.com/watch?v=adLGHcj_fmA   bruno
                        //location: "the/user/the/story",
                        proxyLUT : state.app.settings.ytImportIsBlackAndWhite ? "gray_none" : "none",
                        title: date.toString(), 
                        created_at: created.getTime(),
                        completed_at: null}; 

          const importRequestRef = storyRef.collection('imports').doc(import_req_id)
          importRequestRef.set(import_request)
              .then(() => {
                dispatch({type: "START_IMPORT", payload: {}});
              })
          .catch((error) => {
              console.log('Error creating import request:', import_request)
          })
          console.log('tried_import:', import_request)
      } else {
          console.log('No STORY, request failed to import media')
      }
    }

    function handleUrlFormChange(e) {
      const url = e.target.value
      const valid_start = "www.youtube.com/watch?v="
      const valid_short_code_length = 11
      if (url.includes(valid_start) && url.substr(url.lastIndexOf(valid_start) + valid_start.length).length===valid_short_code_length){
          setUrlValid(true)
      } else {
          setUrlValid(false)
          //console.log("bad short code", url.substr(url.lastIndexOf(valid_start) + valid_start.length))
      }
      setImportUrl(url)
    }

    function handlePromptFormChange(e) {
      const current_prompt = e.target.value
      const max_length = 210
      if (current_prompt.length < max_length) {
          setPromptValid(true)
      } else {
          setPromptValid(false)
      }
      setPrompt(current_prompt)
    }

    function createVeneer(prompt, video_path, shot_id, take_id, cost) {
        const storyRef = state.stories.activeStory.ref
        const seed = -1
        const random_seed = (seed==-1) ? Math.floor(Math.random() * 100000) + 101000000 : 10
        const veneer = {video_path: video_path,  
          audio: 'none',
          prompt: prompt,
          im_strength: 0.66,
          steps: 30,
          scale: 7,
          seed: random_seed,
          sampler: 'ddim',
          skip_frames: 0,
          max_frames: 200,
          shot_id: shot_id,
          take_id: take_id,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          completed_at: null,
          engine: ''
        };
        const veneer_id = nanoid()
        const veneerRef = storyRef.collection('veneer').doc(veneer_id) 
        veneerRef.set(veneer)
             .then(() => {
               dispatch({type: "START_VENEER", payload: {}});
               dispatch({type: "UPDATE_USER_CREDITS", payload: {"credits": state.userData.credits - cost} });

             })
         .catch((error) => {
             console.log('Error creating veneer request:', veneer)
         })
         console.log('tried VENEER:', veneer)
      }


    const import_Dialog = 
        <Dialog id="import"
          onClose={() => setImportFormOpen(false)}
          onOpen={() => setImportFormOpen(true)}
          open={importFormOpen}
        >
          <DialogTitle>Auto Storyboard</DialogTitle>
          <DialogContent>
            <div>
            <Box>
              <p>
                Every scene from the first 60 seconds of a video will become a panel in your storyboard.
              </p>
            </Box>

              <div className="app_container">
                <FormControl sx={{ width:'100%' }}>
                <InputLabel id="form-input-youtube-url" sx={{ '&.MuiInputLabel-shrink': { padding:'0 5px', background:'white' } }}>Youtube URL</InputLabel>
                  <OutlinedInput
                    id='form-input-youtube-url'
                    control={Input}
                    label='Youtube URL'
                    value = {importUrl}
                    onChange={handleUrlFormChange}
                    error={urlValid ? false : {
                              content: 'Please enter a valid url for a video.',
                              pointing: 'above'
                          }}
                  />
                </FormControl>
              </div>
            </div>
            <Box>
              <p>
                You can import creative commons videos from youtube. Please remember to give them credit. 
              </p>
            </Box>

            <Box><p>
                Do you want to append new panels to your storyboard?
            </p></Box>
          </DialogContent>
          <DialogActions>
          { !urlValid && <Box onClick={() => setImportFormOpen(false)}>Invalid URL</Box> }
            <Button variant='outlined' onClick={() => setImportFormOpen(false)}>
              Cancel
            </Button>
            { urlValid && 
              <Button
                labelPosition='right'
                icon='checkmark'
                onClick={importStoryboard}
                positive
                variant='contained'>Create new panels</Button>
            }
          </DialogActions>
        </Dialog>


    shots.filter(s => s!==undefined)

    const take_urls = shots.map(shot => shot.last_take_stream_url).filter(x => x!==undefined)
    // const valid_take_urls = take_urls.filter(x => x!==null)

    // console.log("take on meez", source_list)
    //const url = take
    //const thumb = take.thumbnail

    //const preview_url_source_list = story.last_cut.stream_path; ///  ToDO:  compute this as in Save only when story is there 


    const sequence = computeSequence(story, shots, takes)

    const [activePreviewState, setActivePreviewState] = useState('Live')
    const [activeEditState, setActiveEditState] = useState('Select')
    


    const EditNav = () => {
      return <Box className={'filterContainer'}>
        <Box horizontal>
          <Box floated="left" compact className={'leftFilter'}>
            <Box sx={{ textAlign:'left' }}> {activeEditState} Take </Box>
          </Box>
          <Box floated="right" className={'rightFilter'}>
            <div className={'menuWrapper'}>
              <Menu floated="right" pointing secondary widths={2}>
                <MenuItem 
                  to='Select'
                  name='Select'
                  active={activeEditState === 'Select'}
                  onClick={() => setActiveEditState('Select')}
                >
                  Select
                </MenuItem>
                <MenuItem 
                  to='Recreate'
                  name='Recreate'
                  active={activeEditState === 'Recreate'}
                  onClick={() => setActiveEditState('Recreate')}
                >
                  Recreate
                </MenuItem>
              </Menu>
            </div>
          </Box>
        </Box>
      </Box>
    }
    const PreviewNav = () => {
      return <Box className={'filterContainer'}>
        <Box horizontal>
          <Box floated="left" compact className={'leftFilter'}>
            <Box sx={{ textAlign:'left' }}> {activePreviewState} Video </Box>
          </Box>
          <Box floated="right" className={'rightFilter'}>
            <div className={'menuWrapper'}>
              <Menu floated="right" pointing secondary widths={2}>
                <MenuItem 
                  to='Live'
                  name='Live'
                  active={activePreviewState === 'Live'}
                  onClick={() => setActivePreviewState('Live')}
                >
                  Live
                </MenuItem>
                <MenuItem 
                  to='Saved'
                  name='Saved'
                  active={activePreviewState === 'Saved'}
                  onClick={() => setActivePreviewState('Saved')}
                >
                  Saved
                </MenuItem>
              </Menu>
            </div>
          </Box>
        </Box>
      </Box>
    }
    
    // template
    function selectItemForShot(item) {
      setSelectedItem(item)
    }

    function applySelectShotImageForShot() {
      if (selectedItem &&  selectedShot) {
        const shot_im_payload = {shot_id: selectedShot.id, image: selectedItem.url}
        //console.log("UPDATE_STORY_SHOT_IMAGE", shot_im_payload)
        dispatch({ type: "UPDATE_STORY_SHOT_IMAGE", payload: shot_im_payload });  
        clearSelectedShotAndItem()
      }
    }

    function applySelectTakeForShot() {
      if (selectedItem &&  selectedShot) {
        const take_id = selectedItem.id
        const shot_selected_take_payload = {shot_id: selectedShot.id, take_id: take_id, image: selectedItem.thumbnail, stream_path: selectedItem.stream_path}
        // console.log("UPDATE_SHOT_SELECTED_TAKE", shot_selected_take_payload)
        dispatch({ type: "UPDATE_SHOT_SELECTED_TAKE", payload: shot_selected_take_payload }); 
        clearSelectedShotAndItem()
      }
    }

    function setSelectedShotAndAction(shot, action) {
      setSelectedShot(shot)
      setSelectedShotAction(action)
      if ((typeof shot.prompt !== 'undefined') && shot.prompt.length > 0) {
        setPrompt(shot.prompt)
      }
    }

    function getTakeFileName(shot, take, ext="mp4") {
      if (shot && take) {
        const story_title = story.title ? story.title.split(' ').join('_') : "video";
        const shot_title = shot.header.split(' ').join('_');
        const take_id = take.id
        return ["10z", story_title, shot_title, take_id].join('-') + "." + ext
      }
      else {
        return "10z_video" + "." + ext
      }
    }

    function downloadBlob(gs_path, filename) {
      const storage = firebase.storage();
      const pathReference = storage.ref(gs_path)

      pathReference.getDownloadURL()
        .then((url) => {
          var xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = (event) => {
            var blob = xhr.response;
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
          };
          xhr.open('GET', url);
          xhr.send();
        })
        .catch((error) => {
            console.log("Download FAILED", error.code)
            alert("Download failed,  Reason: " + error.code)

            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/object-not-found':
                // File doesn't exist
                break;
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;
              case 'storage/unknown':
                // Unknown error occurred, inspect the server response
                break;
            }
        });
    }

    // getItemList()
    const shot_type_filenames = ["wide.png", "mid.png","action.png","close.png","empty.png","xcu.png","two_shot.png","ots.png","full.png"] // "action_close.png",
    const selectable_shot_image_list = shot_type_filenames.map(filename => ({url:`https://storage.googleapis.com/v0zebra.appspot.com/resource/shot_type/${filename}`}))
    const selectable_take_list = selectedShot ? takes.filter(take => take.shot_id==selectedShot.id) : []

    const image_selector_Dialog = 
        <Dialog id="image_selector"
          onClose={() => setImageSelectorOpen(false)}
          onOpen={() => setImageSelectorOpen(true)}
          open={selectedShotAction=='select_shot_image'}
        >
          <DialogTitle>
            <Typography variant="h2">Select a shot type</Typography>
          </DialogTitle>

             <DialogContent>
                <Box>
                   <Box sx={{ marginBottom:"1em" }}>
                     Which shot type do you want to use?
                   </Box>
                 </Box>
                 <Grid container spacing={{ xs: 2 }}>
                    {selectable_shot_image_list.map((item, key) => (
                      <Grid xs={4} key={key}>
                        <Item> 
                          <Box component="img" src={item.url} sx={{ width:'100%', height:'auto' }} onClick={() => selectItemForShot(item)} />
                          {selectedItem && item.url==selectedItem.url && <Box sx={{ textAlign:'center' }}>✓ Selected</Box>}
                        </Item>
                      </Grid>
                    ))}
                  </Grid>

              </DialogContent>

              <DialogActions>
                <Button variant="outlined" onClick={() => setSelectedShot(null)}>
                  Cancel
                </Button>
                { selectedItem &&
                  <Button
                    labelPosition='right'
                    icon='checkmark'
                    onClick={applySelectShotImageForShot}
                    positive
                    variant="contained">Select</Button>
                }
              </DialogActions>
        </Dialog>


    const take_selector_Dialog = 
        <Dialog id="image_selector"
          onClose={() => setTakeSelectorOpen(false)}
          onOpen={() => setTakeSelectorOpen(true)}
          open={selectedShotAction=='select_take'}
        >
          <DialogTitle>
              Edit
              <EditNav />
          </DialogTitle>

             <DialogContent>

                {activeEditState === 'Select' && sequence['url'] && <div>
                <Box>
                   <Box>
                     Which take do you want to use?
                   </Box>
                 </Box>
                 </div>}

                {activeEditState === 'Recreate' && sequence['url'] && <div>
                  <Box>
                   <Box>
                     Imagine alternative takes inspired by a reference video clip and a prompt. 
                   </Box>
                 </Box>

                    <FormControl>
                      <OutlinedInput
                        id='form-input-prompt'
                        control={Input}
                        label='Prompt'
                        value = {prompt}
                        onChange={handlePromptFormChange}
                        error={promptValid ? false : {
                                  content: 'Prompt is too long.',
                                  pointing: 'above'
                              }}
                      />
                    </FormControl>
                 </div>}
              </DialogContent>

              <Box>
                <Grid container spacing={{ xs: 2 }}>
                  {selectable_take_list.map((item, key) => (
                    <Grid  sm={4} md={3} lg={2} key={key}>
                      <Item>
                      <Box> 
                        {!selectedItem &&  <img src={item.thumbnail} fluid="true"  onClick={() => selectItemForShot(item)}/>}
                        {selectedItem && item.thumbnail!=selectedItem.thumbnail && <img src={item.thumbnail} fluid="true"  onClick={() => selectItemForShot(item)}/>}
                        {selectedItem && item.thumbnail==selectedItem.thumbnail && <ZebraPlayer video_urls={[item.stream_path]} light={false} controls={false}/>}
                        {selectedItem && item.thumbnail==selectedItem.thumbnail && "✓"}
                        {selectedItem && item.thumbnail==selectedItem.thumbnail &&
                          <label htmlFor={item.id + "file-download"} className="mirco-file-download">
                              <i  className="right floated download icon" onClick={() => downloadBlob(item.video_path, getTakeFileName(selectedShot, item))}></i>
                          </label>
                        }
                        {selectedItem && item.thumbnail==selectedItem.thumbnail &&
                          <label htmlFor={item.id + "file-download"} className="mirco-file-download">
                              <i  className="right floated trash
                         
                         icon" onClick={() => handleTakeDelete(item, selectedShot)}></i>
                          </label>
                        }
                      </Box>
                      </Item>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              

              { activeEditState === 'Recreate' && promptValid &&
           
                  <Box>  
                    <Box>  
                        Cost to run: {cost_per_veneer};   
                        Remaining Credits: {state.userData.credits} 
                    </Box>
                  </Box>}

              <DialogActions>
                <Button variant="outlined" onClick={clearSelectedShotAndItem}>
                  Cancel
                </Button>
                { selectedItem && activeEditState === 'Select' &&
                  <Button
                    content="Select"
                    labelPosition='right'
                    icon='checkmark'
                    onClick={applySelectTakeForShot}
                    positive
                    variant="contained"
                  />
                }


                { selectedItem && activeEditState === 'Recreate' && promptValid && state.userData.credits > cost_per_veneer &&

                  <Button
                    content="Recreate"
                    labelPosition='right'
                    icon='checkmark'
                    onClick={createVeneerForTake}
                    positive
                    variant="contained"
                  />
                }
              </DialogActions>
        </Dialog>

    const vestigal_header = <div className="filters btn-group stack-exception">
          <button type="button" className="btn toggle-image-display" onClick={toggleDisplayView}> View </button>
          <button type="button" className="btn toggle-image-display" onClick={runTest}> Test </button>
          <button type="submit" className="btn__inline" onClick={randomStory}> Random Template </button>
        </div>

    function make_new_board() {
      //close_sidebar()
      history.push('/new')
    }

    function share_project() {
      //close_sidebar()
      // setDisplayShare(true)  to effect the next page, maybe move this to state.app.displayShare ?
      startCollecting(true)
    }

    function toggle_view_takes() {
      setViewLatestTake(!viewLatestTake)
      //setDisplayImages(!displayImages)
      //console.log("viewLatestTake", viewLatestTake)
    }

    function handeTakeView(value) {

    }


    function start_demo() {
      history.push('/demo1')
    }

    function handleTakeView() {
      toggle_view_takes()
    }

    function close_sidebar() {
      setSidebarVisible(false)
    }

    function goToNew() {
      history.push('/new')
    }

    const description = story.description ?  story.description : "Let's make a video together. Tap any image below to contribute a 1-6 second clip. Thanks! ";

    function setStoryDesc(story_id, desc) {
      const payload = {proj_id: story_id, description: desc}
      dispatch({ type: "UPDATE_STORY_DESCRIPTION", payload: payload });
    }

    const share_menu_item = <MenuItem component='a' onClick={share_project}>
              <ShareIcon />
              Share
            </MenuItem>

    const new_project_menu_item = <MenuItem component='a' onClick={make_new_board}>
              <GridViewIcon />
              New Project
            </MenuItem>


    const preview_menu_item = <MenuItem component='a'>
                      <PlayArrowIcon onClick={close_sidebar}/>
                      Preview
                    </MenuItem>


    /*if(sequence['url'] && sequence['url'].length > 0) {
      button1 = {click: updatePreviewFormOpen, type:"secondary", icon:"play", text:"Preview"}
    }*/
  

          /* ADD BACK SHOT COUNT */
         /* { && <Segment className='info-container' textAlign='center'>
            <Icon name='genderless grey' onClick={start_demo} />
            {num_shots} shots ~ {Math.floor(num_shots * 2.5)} sec
          </Segment>}*/
          /*{num_shots <= 2 && <Segment className='info-container' textAlign='center'> 
            Describe your story with shots.
          </Segment>}*/

          {num_shots > 2 && <Box className='submit-container' sx={{ textAlign:'right' }}>
            <Button variant="contained" onClick={startCollecting} startIcon={<CircleIcon />}> Collect</Button>
          </Box>}

    // <ShareDialog name="share" />
    //    this does not work for sharing from a non-portal site.  would need to update interface and re-consider edge cases
    return( 
      <Container className="has-footbar" sx={{ maxWidth:'100% !important', paddingLeft:'0 !important', paddingRight:'0 !important' }}>
        {state.app.importing && <AlertImportStoryboardStatus />}
        <Headbar settings={{storyboard:{title:'Auto Storyboard', action:setImportFormOpen}}} />
        {import_Dialog}
        <Box sx={{ padding:"3em", [theme.breakpoints.down('md')]: { padding:"1.5em" } }}>
          {false && <Box horizontal className={'processHeader'}>
            <Box className='submit-container' sx={{ textAlign:'right' }}>
              <MenuIcon onClick={open_sidebar} />
             </Box>
          </Box>}
          <h2>test</h2>
          <Box className="description" sx={{ marginBottom:'25px', width:'75%', maxWidth:'750px' }}>{state.app.userIsStoryOwner && <Typography variant="body2"><EditableField 
                          init={description} 
                          setter={setStoryDesc} 
                          story_id={story.portal_id} 
                          textClassName="input input__cd title_cd" 
                          num_rows={4}     
                          max_length={500}
        /></Typography>}{(!state.app.userIsStoryOwner) && <Typography variant="body2" sx={{ /*padding:'8px 3px 11px'*/ }}>{ description }</Typography>}</Box>
          { false && <Box className='inner-menu-header'>
            <Typography variant="h5"> 
              <SettingsIcon onClick={open_sidebar} />
            </Typography>
            {false && !state.app.isMobile && sequence['url'] && <Box floated='right'>     
              <PlayArrow onClick={() => setPreviewFormOpen(true)}/>
            </Box>}
          </Box> } 
         {selectedShot && image_selector_Dialog}
         {selectedShot && take_selector_Dialog}
        <Box sx={{ minHeight:'60vh' }}>
          <Box>
          <Box>
            {("parts" in story) && story.parts.map((part, key) => (
              <Box key={key} sx={{ marginBottom:'2em' }}>
                <ShotGroup 
                  title={part.title} 
                  handleShotDelete={handleShotDelete} 
                  displayImages={displayImages} 
                  viewLatestTake={viewLatestTake} 
                  part_id={part.id} 
                  addShot={addShot} 
                  setSelectedShotAndAction={setSelectedShotAndAction}
                  takes={takes}
                  shots={part.order.map(shot_id => shots.filter(shot => shot.id===shot_id)[0])}
                />
              </Box>
            ))}
            </Box>
          </Box>
          </Box>
          </Box>
        <Footbar buttons={[footbarBtn]} />
      
      
    </Container>
      
    )
}

export default Scene