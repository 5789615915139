import React, { useContext, useEffect, useState } from "react";
import AppContext from "../utils/app-context";
import { useHistory } from "react-router-dom";
// import "../components/todo-parts.css"
// import "../components/pmm.css"
//import RecordableShotGroup from "../components/recordable-shot-group";
import AlertUploadStatus from './alert-upload-status';
import { nanoid } from "nanoid";

import { Icon, Box, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import Item from '@mui/material/Unstable_Grid2'

import { v4 as uuidv4 } from 'uuid';
import firebase from '../utils/firebase';

const init_shot1 = [{
    header: 'Sample Shot',
    description: 'A short descrition so contributors know what to film.  Like "People having fun as they wait in line to enter"',
    meta: 'Outside the venue',
    image: "https://storage.googleapis.com/v333m-clip/tgt/tour10/th/thmb001a.jpg"
  }
]

 const init_shots =  [{ header: "Goal",
      description: 'HERO wants X',
      meta: 'Establish character & context',
      image: "https://storage.googleapis.com/v333m-clip/tgt/tour10/th/thmb009a.jpg",
      id: "example-4NNPGRgIT6CbzCXLNHerL",
      deleted_at: null
    },
    { header: 'Challenge',
      description: 'Faces OBSTACLE',
      meta: 'Emotional arc before & after obstacle',
      image: "https://storage.googleapis.com/v333m-clip/tgt/tour10/th/thmb004a.jpg",
      id: "example-4NNPGRgIT6CbzCXLNHerM",
      deleted_at: null      
    },
    { header: "Outcome",
      description: 'This is what happened',
      meta: 'Reveal uncertainty',
      image: "https://storage.googleapis.com/v333m-clip/tgt/tour10/th/thmb001a.jpg",
      id: "example-4NNPGRgIT6CbzCXLNHerN",
      deleted_at: null
    }]


// READ ME: this is an old version of the code, AND does not require the cloud to update a local state, 
// HOW was it made:  Modify produce.js , remove RecordableShotGroup and place similar functions here.
// these takes are actually uploaded to the users first story. 
//      SO:  we swapped out differt shot IDs so they don't show up later. 
// files ARE actually uploaded and placed into "example_uploader" folder

function ExampleProduction(props) {

    const [displayImages, setDisplayImages] = useState(true)

    const history = useHistory();
    const { state, dispatch } = useContext(AppContext);
    const story = state.story

    const [shots, setShots] = useState(init_shots)
    const [takes, setTakes] = useState([])
    //const [shots, setShots] = useState(story.shots)


    const counter = arr => arr.reduce((prev, curr) => (prev[curr] = ++prev[curr] || 1, prev), {});
    //const takes_per_shot = counter(state.story.takes.map(take => take.shot_id))
    const takes_per_shot = counter(takes.map(take => take.shot_id))


   

    // const takes_per_shot = {"id-4NNPGRgIT6CbzCXLNHerL": 2,
    //   "id-4NNPGRgIT6CbzCXLNHerM":1,
    //   "id-4NNPGRgIT6CbzCXLNHerN":2}



    function toggleDisplayView() {
      setDisplayImages(!displayImages) 
    }

    function runTest() {
      alert("all tests passed") 
    }

    function OnFileUploadChange() {
      alert("OnFileUploadChange") 
    }

    function doShare() {
          alert("Text or email this link to let others add video:  https://okapi-webdev.web.app/example") 
    }

      const uploadBlob = (blob, dispatch, path) => {
          const storageRef = firebase.storage().ref();

          dispatch({ type: "START_UPLOAD", payload: { totalBytes: blob.size, totalFiles: 1 } });

          let uploadResolve, uploadReject;
          const uploadPromise = new Promise((resolve, reject) => {
              uploadResolve = resolve;
              uploadReject = reject;
          });

          const blobRef = storageRef.child(path)

          const uploadNext = (snapshot) => {
              // const percent = snapshot.bytesTransferred / snapshot.totalBytes * 100;
              const bytes = snapshot.bytesTransferred;
              dispatch({ type: "FILE_UPLOAD_UPDATE", payload: { path, bytes } });
          }

          const uploadError = (error) => {
              console.log(`Error uploading blob`, error)
              uploadReject(error)
          }

          const uploadComplete = () => {
              dispatch({ type: "FILE_UPLOAD_SUCCESS", payload: {} });
              uploadResolve('upload completed');
          }

          const blobUploadTask = blobRef.put(blob);

          blobUploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, {
              'next': uploadNext,
              'error': uploadError,
              'complete': uploadComplete
          });

          return uploadPromise;
      }

      async function onFileUploadChange(shot_id, event) {

          for (var i = 0; i < event.target.files.length; i++ ) {
            const file = event.target.files[i];
            if (file) {
              const file_MB = file.size / 1000000
              const max_MB = 25
              if (file_MB > max_MB) {
                alert(`Please use shorter video clips. ${file_MB.toPrecision(3)}MB is over the ${max_MB}MB limit.`)
              } else {
                console.log(file)
                console.log("onFileUploadChange ... " + shot_id + " " + file_MB.toPrecision(3) + "MB")

                const filename = uuidv4();
                const storageDir = "example_uploader"
                const path = `${storageDir}/${filename}.mp4`
                const uploadPromise = uploadBlob(file, dispatch, path)
                let result = await uploadPromise;

                if (result ==='upload completed') {
                  const payload = {video_path: path, 
                    thumbnail:"https://okapivideo.com/wp-content/uploads/2021/05/thumbnail_great.jpeg",
                    shot_id: shot_id,
                    take_id: uuidv4(),
                  }
                  const new_takes = takes.concat([payload])
                  setTakes(new_takes)
                  //takes.push()
               }
              }
          } else {
              console.log("onFileUploadChange ... empty file ... canceled event") 
          }
        }
        console.log(shot_id)
      }

      function onClickedShot(shot_id, event) {
          takes_per_shot[shot_id] = takes_per_shot[shot_id] + 1
      }



        // {story.parts.map((part, key) => (
        //   <RecordableShotGroup id={key} title={part.title}  displayImages={displayImages} handleShotDelete={OnFileUploadChange} 
        //       shots={part.order.map(shot_id => story.shots.filter(shot => shot.id==shot_id)[0])}
        //   />
        // ))}


    const vestigal_header =         <div className="filters btn-group stack-exception">
          <button type="button" className="btn toggle-image-display" onClick={toggleDisplayView}> View </button>
          <button type="button" className="btn toggle-image-display" onClick={runTest}> Test </button>
          <button type="button" className="btn toggle-image-display" onClick={doShare}> Share </button>
        </div>

    return( 
    
      <div className="Scene app_container">
        {state.app.upload && <AlertUploadStatus />}

        <h1>Collect </h1>  
        Add a video clip to any planned shot. 
        You can invite people to contribute.
        <br />
        { false && <button type="submit" className="btn__inline" onClick={doShare}> Share </button>}
        <br />
        <br />


          <div>
          <Header size='large'> Example requested shots </Header> 
          <Grid container columnSpacing={{ xs: 2, md: 8 }} sx={selectedStyles}>
          { selected_take }
            {shots.map((shot, key) => {
              <Grid xs={8} md={8} key={shot.id + "card"}>
                <Item>
                  {displayImages &&
                  <label htmlFor={shot.id + "file-capture"} className="image_wrapper_label image-file-upload" >
                      <Box component="image" src={shot.image} fluid="true" onClick={(e) => onClickedShot(shot.id, e)}/>
                  </label>}
                  <input id={shot.id + "file-capture"} type="file" onChange={(e) => onFileUploadChange(shot.id, e)} accept="video/*" capture="environment"/>

                <Box> 
                  <label htmlFor={shot.id + "file-upload"} className="mirco-file-upload">
                      <i  className="right floated upload icon" ></i>
                  </label>
                  <input id={shot.id + "file-upload"} type="file" onChange={(e) => onFileUploadChange(shot.id, e)} accept="video/*" multiple/>         
                  {displayImages && <br />}

                  <Box> {shot.header} </Box>
                  <Box> {shot.description} </Box>

                </Box>



                {takes_per_shot[shot.id] && <Box extra>
                  <a><Icon name='video' /> {takes_per_shot[shot.id]} { takes_per_shot[shot.id]==1 ? 'take' :'takes' } </a>
                </Box>}
                </Item>
              </Grid>
            })}
          </Grid>
      </div>

      </div>
    )
}


export default ExampleProduction