import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { Box, Grid, IconButton, Typography } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import ReactPlayer from 'react-player';
import AppContext from "../utils/app-context";
import Headbar from './Headbar';
import EditableField from "../components/editable-field";
import theme from '../styles/theme'
import  SortableItem  from "./SortableItem";
const styles = {
    iconContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        position: 'relative',
        zIndex: 1, 
        padding:"10px"
    },
    iconStyle: {
        marginLeft: "10px",
        color:theme.palette.tertiary.contrastText,  
    },
 
    gridItem: {
        position: 'relative',
        mb: 4,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)',
        marginBottom: '50px'
    },
    muteButton: {
        position: 'absolute',
        top: 8,
        right: 8,
        zIndex: 2
    },
    takeCount: {
        position: 'absolute',
        zIndex: 99,
        left: '5px',
        bottom: '9px',
        backgroundColor: '#272727',
        color: 'white',
        padding: '1px 6px 1px 6px',
        fontSize: '.7em',
        lineHeight: '2em',
        textDecoration: 'none',
        transition: '.5s all',
        '&:hover': { backgroundColor: '#B4B4B4', cursor: 'pointer', color: '#000' }
    },
    
};


const Strip = ({ onEnded = () => {}, volume = 1.0, controls = false, loop = true,props }) => {
    const { state, dispatch } = useContext(AppContext);
    const [shotsData, setShotsData] = useState([]);
    const history = useHistory();
    const playersRef = useRef([]);
    const observersRef = useRef([]);
    const [isMuted, setIsMuted] = useState(false);
    const [playbackStatus, setPlaybackStatus] = useState({});
    const params = useParams();

    const takes = state.stories.activeTakeList

    const takesPerShot = (shotId) => {
        if (!takes) return 0;
        return takes.filter(take => take.shot_id === shotId).length;
      };

    const story = state.stories.activeStory;

    const portal_id = (state.stories.activeStory.portal_id) ? state.stories.activeStory.portal_id : state.app.portal.id
    

    useEffect(() => {
        const stories = state.stories.activeShotList || [];
        const uniqueShots = Array.from(new Map(stories.map(shot => [shot.id, shot])).values());
        setShotsData(uniqueShots);
    }, [state.stories.activeShotList]);

    useEffect(() => {
        if (playersRef.current.length !== shotsData.length) return;

        observersRef.current = playersRef.current.map((player, index) => {
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.8,
            };

            const observerCallback = (entries) => {
                entries.forEach(entry => {
                    if (player) {
                        if (entry.isIntersecting) {
                            setPlaybackStatus(prev => ({ ...prev, [index]: true }));
                        } else {
                            setPlaybackStatus(prev => ({ ...prev, [index]: false }));
                        }
                    }
                });
            };

            const observer = new IntersectionObserver(observerCallback, options);
            const playerElement = player ? player.getInternalPlayer() : null;
            if (playerElement) {
                observer.observe(playerElement);
            }
            return observer;
        });

        return () => {
            observersRef.current.forEach(observer => observer.disconnect());
        };
    }, [shotsData]);

    const handleShotDelete = (shot) => {
        if (window.confirm("Are you sure you want to delete this shot?")) {
            dispatch({ type: "DELETE_STORY_SHOT", payload: { shot_id: shot.id } });
            setShotsData(prevShotsData => prevShotsData.filter(s => s.id !== shot.id));
        }
    };

    const toggleMuteAll = () => {
        setIsMuted(prev => !prev);
    };

     
    const handleHeaderUpdate = (shot_id, text) => {
        if (text !== undefined && text !== null) {
            setShotsData(prevShotsData =>
                prevShotsData.map(shot =>
                    shot.id === shot_id ? { ...shot, header: text } : shot
                )
            );
            dispatch({ type: "UPDATE_STORY_SHOT_TITLE", payload: { shot_id, text } });
        } else {
            // console.error("Invalid text:", text);
        }
    };

    const goTake = (shotId) => {
        if (portal_id) {
            history.push(`/p/${portal_id}/${shotId}?strip=true`);
        }
    };
    

    const handleDescriptionUpdate = (shot_id, text) => {
        
        if (text !== undefined && text !== null) {
            setShotsData(prevShotsData =>
                prevShotsData.map(shot =>
                    shot.id === shot_id ? { ...shot, description: text } : shot
                )
            );
            dispatch({ type: "UPDATE_STORY_SHOT_DESCRIPTION", payload: { shot_id, description: text } });
        }
    };

     
    const filteredShots = shotsData.filter(s => s !== undefined);
    const totalShots = filteredShots.length;
    const estimatedDuration = Math.floor(totalShots * 2.5);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: '100vh' }}>
            <Headbar title={"Strip view"} share={true} starred={true} fork={true} settings={{ storyboard: { title: 'Auto Strip' } }} />

            
            <Grid container sx={{padding: 2, justifyContent: 'center', alignItems: 'center', width: '100%', maxWidth: '864px', margin: '0 auto' }}>
            <Box sx={{marginBottom:'20px' }}>
                {`${totalShots} shots ~ ${estimatedDuration} sec`}
            </Box>
                {shotsData.map((shot, index) => {
                    const takeCount = takesPerShot(shot.id)
                    return <Grid item xs={12} key={shot.id} sx={styles.gridItem}>
                        <Box sx={styles.iconContainer}>
                            <Link
                                style={{ marginLeft:"10px" }}
                                className="icon"
                                onClick={() => handleShotDelete(shot)}
                                aria-label="Delete shot"
                            >
                                <DeleteIcon />
                            </Link>
                            {shot.last_take_stream_url && (
                                <Link
                                    style={{ marginLeft:"10px" }}
                                    className="icon"
                                    onClick={toggleMuteAll}
                                    aria-label={isMuted ? "Unmute all" : "Mute all"}
                                >
                                    {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                                </Link>
                            )}
                        </Box>
                        {shot.deleted_at === null && shot.last_take_stream_url ? (
                            <Box position="relative" sx={{ cursor: 'pointer' }} >
                                <ReactPlayer
                                    onClick={() => goTake(shot.id)}
                                    url={shot.last_take_stream_url}
                                    width="100%"
                                    height="auto"
                                    ref={el => playersRef.current[index] = el}
                                    controls={controls}
                                    allowFullScreen={false}
                                    playsinline={true}
                                    volume={isMuted ? 0 : volume}
                                    loop={loop}
                                    onEnded={onEnded}
                                    playing={playbackStatus[index] || false}
                                    muted={isMuted}
                                />
                                <Box sx={styles.takeCount}>
              <Box
                onClick={() => goTake(shot.id)}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  color: 'white',
                  textDecoration: 'none',
                    transition: '.5s all',
                  zIndex:'100',
                  '&:hover': { backgroundColor: '#B4B4B4', cursor: 'pointer', color: '#000' }
                }}
              >
                <Box component="span" sx={{ color: 'white' }}>
                                                {takeCount > 0 ? takeCount : '+'}
                </Box>
              </Box>
            </Box>
                            </Box>
                        ) : (
                            <Box position="relative" sx={{ cursor: 'pointer' }}>
                                <img
                                    onClick={() => goTake(shot.id)}
                                    src={shot.image}
                                    alt={shot.header}
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        objectFit: 'cover',
                                        borderRadius: 1,
                                        cursor: 'pointer',
                                        display:'block'
                                    }}
                                />
                           
                             <Box sx={styles.takeCount}>
              <Box
                onClick={() => goTake(shot.id)}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  color: 'white',
                  textDecoration: 'none',
                    transition: '.5s all',
                  zIndex:'100',
                  '&:hover': { backgroundColor: '#B4B4B4', cursor: 'pointer', color: '#000' }
                }}
              >
                <Box component="span" sx={{ color: 'white' }}>
                                                {takeCount > 0 ? takeCount : '+'}
                </Box>
              </Box>
            </Box>
          </Box>
                                 
                          
                        )}

                         
                        <Box sx={{marginLeft:'7px'}}>
                            {state.app.userIsStoryOwner ? (
                                <Typography variant="h1">
                                    <EditableField
                                        init={shot.header}
                                        setter={handleHeaderUpdate}
                                        shot_id={shot.id}
                                        textClassName="input input__cd title_cd"
                                        num_rows={1}
                                        max_length={100}
                                    />
                                </Typography>
                            ) : (
                                <Typography variant="h1" >
                                    {shot.header}
                                </Typography>
                            )}
                        </Box>

                         
                        <Box sx={{marginLeft:'7px',marginBottom:'15px' }}>
                            {state.app.userIsStoryOwner ? (
                                <Typography variant="body2">
                                    <EditableField
                                        init={shot.description}
                                        setter={handleDescriptionUpdate}
                                        shot_id={shot.id}
                                        textClassName="input input__cd description_cd"
                                        num_rows={3}
                                        max_length={500}
                                    />
                                </Typography>
                            ) : (
                                <Typography variant="body2">
                                    {shot.description}
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                })}
            </Grid>

            
          
        </Box>
    );
};

export default Strip;
