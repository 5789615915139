import "../components/pmm.css";

import { Box, Button, Container, Dialog, DialogActions, DialogContent,List,ListItem, DialogContentText, DialogTitle, Icon, Link, Menu, MenuItem, Typography } from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { computePercentFilmed, computeSequence } from "../utils/sequence.js";
import firebase, { analytics } from "../utils/firebase.js";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AlertUploadStatus from "./alert-upload-status";
import AppContext from "../utils/app-context";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import Footbar from "./Footbar";
import Grid from "@mui/material/Unstable_Grid2";
import Headbar from "./Headbar";
import Item from "@mui/material/Unstable_Grid2";
import LaunchIcon from "@mui/icons-material/Launch";
import MotionPhotosAutoIcon from "@mui/icons-material/MotionPhotosAuto";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import RecordableShotGroup from "../components/recordable-shot-group";
import ShareIcon from "@mui/icons-material/Share";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import ViewToggle from "../components/toggle-view";
import ZebraPlayer from "../components/zebra-player";
import { css } from "@emotion/css";
import { formatDate } from "../utils/utility.js";
import { nanoid } from "nanoid";
import navLogo from "../images/nav-logo.svg";
import theme from "../styles/theme";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { withStyles } from "@mui/styles";
import assets from "../utils/assets";

const topWrapperStyles = {
  display: "flex",
  justifyContent: "center",
  marginBottom: "4em",
  [theme.breakpoints.down("sm")]: {
    flexFlow: "column",
  },
};

const currStoryStyles = {
  order: 0,
  width: "50%",
  [theme.breakpoints.down("sm")]: {
    order: 1,
    width: "100%",
  },
};

const newStoryStyles = {
  order: 1,
  width: "50%",
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    order: 0,
    width: "100%",
    marginBottom: "4em",
  },
};

function Storyboards(props) {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const [playing, setPlaying] = useState(null);
  const [playActive, setPlayActive] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedStoryboard, setSelectedStoryboard] = useState(null);
  const [deletedStoryboard, setDeletedStoryboard] = useState(null);
  const user = state.userData;
  let title = null;

  const [view, setView] = useState('mine');
  
  

  
  const userStarredStories = state.stories.starredStories;
  // console.log('User Starred Stories:', userStarredStories);

 

  const setActiveStory = (board) => {
    if (board.portal_id != activeStory.portal_id) {
      const payload = {
        activeStory: board,
      };
      if (user && user.ref && board.ref) {
        user.ref
          .update({ lastActiveStory: board.ref })
          .then(() => {
            dispatch({ type: "UPDATE_ACTIVE_STORY", payload: payload });
            console.dir(payload.activeStory.portal_id);
            history.push("/p/" + board.portal_id);
          })
          .catch((error) => {
            console.error(
              "Error writing activeStoryRef to user document in database: ",
              error
            );
          });
      }
    } else {
      history.push("/p/" + activeStory.portal_id);
    }
  };

  const setDeleteStory = (board) => {
    const payload = {
      activeStory: board,
    };
    setDeletedStoryboard(board.ref);
  };

  const emptyStory = (init_shot_id) => {
    const created = new Date();
    return {
      parts: [{ title: "Storyboard", order: [init_shot_id] }],
      last_cut: null,
      title: formatDate(created, "title"),
      portal_id: nanoid(12),
      created_at: created.getTime(),
      shots: [
        {
          header: "Shot 1",
          description: "HERO starts JOURNEY",
          meta: null,
          // here
          image: assets["empty_thumbnail"],
          // image:
          //   "https://storage.googleapis.com/v0zebra.appspot.com/resource/shot_type/wide.png",
          id: init_shot_id,
          deleted_at: null,
        },
      ],
    };
  };

  const goToBoard = (id) => {
    history.push("/p/" + id);
  };

  const deleteStory = (board) => {
    confirm("Delete Story is coming soon");
  };

  const getShotCount = (board) => {
    let count = 0;
    for (let i in board.parts) {
      if (board.parts[i].order) {
        for (let shot in board.parts[i].order) {
          count++;
        }
      }
    }
    return count;
  };

  const handleContinueEmpty = () => {
    const id = uuidv4();
    const story = emptyStory(id);
    dispatch({ type: "NEW_ACTIVE_STORY", payload: { newStory: story } });
    history.push("/p/" + story.portal_id);
  };

  const boardsWrapStyles = {
    minHeight: "60vh",
    padding: "3em",
    [theme.breakpoints.down("md")]: {
      padding: "1.5em",
    },
  };

  const viewStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    //cursor:'pointer',
    /*background:theme.palette.primary.contrastText, */
    color: theme.palette.tertiary.contrastText,
    padding: ".5em",
    // transition:'all .5s ease-in-out',
    // 'svg, p': {
    //   transition:'all .5s ease-in-out',
    // },
    // '&:hover': {
    //   color:theme.palette.primary.dark,
    //   'svg, p' : {
    //     color:theme.palette.primary.dark,
    //   }
    //}
  };

  const iconStyles = {
    color: theme.palette.tertiary.contrastText,
    marginRight: ".25em",
  };

  const setActiveStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: "all .5s ease-in-out",
    "svg, p": {
      transition: "all .5s ease-in-out",
    },
    "&:hover": {
      color: theme.palette.primary.dark,
      "svg, p": {
        color: theme.palette.primary.dark,
      },
    },
  };

  const hoverStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all .5s ease-in-out",
    "svg, p": {
      transition: "all .5s ease-in-out",
    },
    "&:hover": {
      color: theme.palette.primary.dark,
      a: {
        cursor: "pointer",
      },
      "svg, p": {
        color: theme.palette.primary.dark,
      },
    },
  };

  const storyStyles = {
    borderRadius: "15px",
    border: "1px solid" + theme.palette.tertiary.contrastText,
    overflow: "hidden",
    "&.no-image .title-wrap": {
      height: "100%",
    },
    "&.no-image .dupe": {
      alignSelf: "end",
    },
    "&.no-image h4": {
      fontSize: "48px",
      lineHeight: "68px",
      textAlign: "center",
    },
    [theme.breakpoints.down("md")]: {
      "&.no-image h4": {
        fontSize: "30px",
      },
    },
  };
  const itemStyles = {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    h4: {
      margin: 0,
    },
  };

  function orderBoards(a, b) {
    /*let val = 0;
    if(a.created_at && b.created_at) {
      if(typeof a.created_at == 'object') val = 1
      else if(typeof b.created_at == 'object') val = -1
      else {
        val = (a.created_at > b.created_at) ? -1 : 1
      }
    } else if(a.created_at) {
      val = -1
    } else {
      val = 1
    }
    return val;*/
    let adate =
      a.created_at && a.created_at.seconds
        ? a.created_at.seconds * 1000
        : a.created_at
        ? a.created_at
        : 0;
    let bdate =
      b.created_at && b.created_at.seconds
        ? b.created_at.seconds * 1000
        : b.created_at
        ? b.created_at
        : 0;
    return bdate - adate;
  }

  const boards = state.stories.storyList
    .filter((story) => story.portal_id != null && story?.deleted_at == null)
    .sort(orderBoards);
  const activeStory = state.stories.activeStory;
  //const lastStory = state.userData.lastActiveStory
  const backBtn = {
    click: () => {
      history.push("/p/" + activeStory.portal_id);
    },
    type: "primary",
    text: "Back to Active",
  };

  const playBoard = (board) => {
    setPlaying(board.portal_id);
  };
  const getStarredBoards = () => {
    return state.stories.storyList
      .filter((story) => userStarredStories.includes(story.portal_id))
      .sort(orderBoards);
  };
  const starredBoardList = (
    <Grid container spacing={2}>
      {getStarredBoards().map((board) => (
        <Grid xs={6} sm={4} md={3}>
          <Item sx={storyStyles} className={!board.last_cut ? "no-image" : ""}>
            <Box sx={itemStyles}>
              <Box sx={viewStyles}>
                <Box variant="span">
                  <Link onClick={() => setActiveStory(board)} sx={setActiveStyles}>
                    <LaunchIcon sx={iconStyles} />
                    <Typography variant="body1">Open</Typography>
                  </Link>
                </Box>
                <Box
                  component="span"
                  sx={{ background: theme.palette.tertiary.light, padding: "0 .5em", lineHeight: "20px", fontSize: "16px", borderRadius: "3px", display: "flex", alignItems: "center" }}
                >
                  <VideoCameraBackIcon sx={iconStyles} />
                  {getShotCount(board)}
                </Box>
              </Box>
              {board.last_cut && (
                <>
                  {board.last_cut.stream_path && playing == board.portal_id && (
                    <Box>
                      <ZebraPlayer sx={{ width: "100%", height: "auto", display: "block" }} video_urls={[board.last_cut.stream_path]} light={false} controls={false} />
                      <FitScreenIcon sx={{ position: "absolute", display: "block", bottom: "30px", right: "10px", color: theme.palette.primary.contrastText }} />
                    </Box>
                  )}
                  {board.last_cut.thumbnail && playing != board.portal_id && (
                    <Box
                      component="img"
                      onClick={() => {
                        playBoard(board);
                      }}
                      src={board.last_cut.thumbnail}
                      sx={{ width: "100%", height: "auto", display: "block" }}
                    />
                  )}
                </>
              )}
              <Box sx={{ padding: "15px", display: "flex", justifyContent: "space-between", alignItems: "center" }} className="title-wrap">
                <Typography variant="h4">{board.title != undefined && typeof board.title == "string" ? board.title : "Untitled"}</Typography>
              </Box>
            </Box>
          </Item>
        </Grid>
      ))}
    </Grid>
  );

  const deleteStoryBoard = () => {
    setIsDeleteModalVisible(false);
    if (deletedStoryboard?.ref) {
      dispatch({
        type: "DELETE_STORYBOARD",
        payload: { boardRef: deletedStoryboard?.ref },
      });
    } else {
      toast.error("Cannot get storyboard data");
    }
  };

  const handleDeleteStoryBoard = (storyBoard) => {
    console.log("storyboard deleted", storyBoard);
    setIsDeleteModalVisible(true);
    setDeletedStoryboard(storyBoard);
  };
  if (deletedStoryboard) console.log("deleted boards", deletedStoryboard);
  console.log("selected boards", boards);

  const boardList = (
    <Grid container spacing={2}>
      {boards.map((board) => (
        <Grid xs={6} sm={4} md={3}>
          <Item sx={storyStyles} className={!board.last_cut ? "no-image" : ""}>
            <Box sx={itemStyles}>
              <Box sx={viewStyles}>
                <Box variant="span">
                  <Link
                    onClick={() => setActiveStory(board)}
                    sx={setActiveStyles}
                  >
                    <LaunchIcon sx={iconStyles} />
                    <Typography variant="body1">Open</Typography>
                  </Link>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Link
                    sx={setActiveStyles}
                    onClick={() => handleDeleteStoryBoard(board)}
                  >
                    <DeleteIcon sx={iconStyles} />
                  </Link>
                  <Box
                    component="span"
                    sx={{
                      background: theme.palette.tertiary.light,
                      padding: "0 .5em",
                      lineHeight: "20px",
                      fontSize: "16px",
                      borderRadius: "3px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <VideoCameraBackIcon sx={iconStyles} />
                    {getShotCount(board)}
                  </Box>
                </Box>
                {/*<Box variant="span"><Link onClick={ () => deleteStory(board) } sx={ setActiveStyles }><DeleteIcon sx={ iconStyles } /></Link></Box>*/}
              </Box>
              {board.last_cut && (
                <>
                  {board.last_cut.stream_path && playing == board.portal_id && (
                    <Box>
                      <ZebraPlayer
                        sx={{ width: "100%", height: "auto", display: "block" }}
                        video_urls={[board.last_cut.stream_path]}
                        light={false}
                        controls={false}
                      />
                      <FitScreenIcon
                        sx={{
                          position: "absolute",
                          display: "block",
                          bottom: "30px",
                          right: "10px",
                          color: theme.palette.primary.contrastText,
                        }}
                      />
                    </Box>
                  )}
                  {board.last_cut.thumbnail && playing != board.portal_id && (
                    <Box
                      component="img"
                      onClick={() => {
                        playBoard(board);
                      }}
                      src={board.last_cut.thumbnail}
                      sx={{ width: "100%", height: "auto", display: "block" }}
                    />
                  )}
                </>
              )}
              <Box
                sx={{
                  padding: "15px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="title-wrap"
              >
                <Typography variant="h4">
                  {board.title != undefined && typeof board.title == "string"
                    ? board.title
                    : "Untitled"}
                </Typography>
              </Box>
              {/*!board.last_cut &&
                    <Box sx={{ width:'100%', aspectRatio: '2 / 1.135', display:'block', background:theme.palette.secondary.light }} /> */}
            </Box>
          </Item>
        </Grid>
      ))}
    </Grid>
  );

  console.log("activeStory", activeStory);

  const currBoard = (
    <Box sx={storyStyles}>
      <Box sx={viewStyles}>
        <Box variant="span">
          <Link
            onClick={() => setActiveStory(activeStory)}
            sx={setActiveStyles}
          >
            <LaunchIcon sx={iconStyles} />
            <Typography variant="body1">Open</Typography>
          </Link>
        </Box>
        <Box
          component="span"
          sx={{
            background: theme.palette.tertiary.light,
            padding: "0 .5em",
            lineHeight: "20px",
            fontSize: "16px",
            borderRadius: "3px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <VideoCameraBackIcon sx={iconStyles} />
          {getShotCount(activeStory)}
        </Box>
        {/*<Box variant="span"><Link onClick={ () => deleteStory(activeStory) } sx={ setActiveStyles }><DeleteIcon sx={ iconStyles } /></Link></Box>*/}
      </Box>
      {activeStory.last_cut && (
        <>
          {activeStory.last_cut.stream_path && playActive && (
            <Box>
              <ZebraPlayer
                sx={{ width: "100%", height: "auto", display: "block" }}
                video_urls={[activeStory.last_cut.stream_path]}
                light={false}
                controls={false}
              />
              <FitScreenIcon
                sx={{
                  position: "absolute",
                  display: "block",
                  bottom: "30px",
                  right: "10px",
                  color: theme.palette.primary.contrastText,
                }}
              />
            </Box>
          )}
          {activeStory.last_cut.thumbnail && !playActive && (
            <Box
              component="img"
              src={activeStory.last_cut.thumbnail}
              onClick={() => {
                setPlayActive(true);
              }}
              sx={{ width: "100%", height: "auto", display: "block" }}
            />
          )}
        </>
      )}
      {!activeStory.last_cut && activeStory.shots && (
        <Box
          component="img"
          sx={{
            width: "100%",
            display: "flex",
            flexGrow: 1,
            background: "#B4B4B4",
          }}
          src={assets["empty_thumbnail"]}
          fluid="true"
        ></Box>
      )}
    </Box>
  );

 
  const newBoard = (
    <Box
      sx={{
        maxWidth: "500px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: "2em",
        position: "relative",
      }}
    >
      <Box style={{ marginBottom: "1em" }}>
        <Typography variant="h2" sx={{ marginBottom: "1em" }}>
          Want to start a new video sequence?
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: "2em" }}>
          Collaborate with a remote team, or unleash your solo creator skills.
          The power is yours with 10zebra.
        </Typography>
      </Box>
      <Box component="section" sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="contained" size="large" onClick={handleContinueEmpty}> New Storyboard</Button>
      </Box>
      <Box
        sx={{display: "flex",gap: "10px",justifyContent: "center",flexDirection: { xs: "row", sm: "row" },position: { xs: "static", sm: "absolute" }, top: { sm: "0" },right: { sm: "0" },
          width: { xs: "100%", sm: "auto" }, alignItems: "center",marginTop: { xs: "20px", sm: "10px" },
          [`@media (max-width: 700px)`]: {
            flexDirection: "row",flexWrap: "wrap",position: "static",top: "auto", right: "auto",width: "100%",marginTop: "20px", },}}
      >
      </Box>
    </Box>
  );
  return (
    <Container className="has-footbar" style={{ maxWidth: "100%", paddingLeft: 0, paddingRight: 0 }}>
    <Headbar title="Storyboards" breadcrumbs={false} timer={false} />
    <Box sx={boardsWrapStyles}>
      <Box sx={topWrapperStyles}>
        <Box sx={newStoryStyles}>
          {newBoard}
        </Box>          
        <Box sx={currStoryStyles}>
          <Typography variant="h2" sx={{ marginBottom: ".5em" }}>
          Current Storyboard:{" "}
          {activeStory.title ? activeStory.title : "Untitled"}
          </Typography>
          {currBoard}
        </Box>
      </Box>
      <Box sx={{ display:"flex", alignItems:"right", marginBottom:"25px" }}>
        <Box
          component="button"
          onClick={() => setView("mine")} sx={{background: "none", border: "none", cursor: "pointer", marginRight:"25px",  fontSize: "18px", lineHeight: "32px",  color: view === "mine" ? "black" : "inherit", borderBottom: view === "mine" ? "2px solid black" : "2px solid transparent", "&:hover": { borderBottom: "2px solid black"} }}>My Storyboards
        </Box>
        <Box
          component="button"
          onClick={() => setView("starred")}
          sx={{background: "none", border: "none", cursor: "pointer", fontSize: "18px", lineHeight: "32px", color: view === "starred" ? "black" : "inherit", borderBottom: view === "starred" ? "2px solid black" : "2px solid transparent", "&:hover": { borderBottom: "2px solid black"}}}>Starred Storyboards
        </Box>
      </Box>
      {view === 'starred' ? (
        getStarredBoards().length > 0 ? (
          <Box sx={{ marginTop: '20px' }}>
            {starredBoardList}
          </Box>
        ) : (
          <Typography variant="body1" sx={{ marginTop: '20px' }}>
            There are no starred stories.
          </Typography>
        )
      ) : (
        <Box>
          {boards.length && boardList}
        </Box>
      )}
    </Box>
    <Footbar buttons={[backBtn]} />
  </Container>
     
)
 }

export default Storyboards;