import React, { useContext, useState } from "react";
import AppContext from "../utils/app-context";
import { useHistory/*, Link*/ } from "react-router-dom";
import EditableField from "../components/editable-field";
import ReactPlayer from "react-player"
import ResponsivePlayer from "../components/responsive-player"

import { Typography, Box, Icon, Button, Link } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';

import Grid from '@mui/material/Unstable_Grid2'
import Item from '@mui/material/Unstable_Grid2'
import theme from '../styles/theme'

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  TouchSensor,
  PointerSensor,
  MouseSensor,
  useSensor,
  useSensors,
  useDraggable,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable';



import { SortableItem } from './SortableItem';

//import {useParams} from "react-router-dom";

function ShotGroup(props) {

  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const [expanded, setExpand] = useState(true);
  const [isSelected, setIsSelected] = useState(true);
  const story = state.stories.activeStory;
  
  const gridItemStyles = {
    width:'100%', 
    height:'100%', 
    touchAction:'manipulation', 
    '.react_player': { aspectRatio:'16 / 9', height:'auto !important' }, 
  }

  const addItemStyles = {
    width:'100%', 
    height:'100%', 
    display:'flex', 
    justifyContent:'center',
    flexDirection:'column', 
    alignItems:'center',
    padding:'1em'
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 300,
        tolerance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: '10z-shots',
  });

  const titleStyles = {
    display:'flex', 
    alignItems:'center', 
    marginBottom:'25px', 
    justifyContent:'space-between',
    'h2': {
      margin:0
    }, 
    [theme.breakpoints.down('md')]: {
      flexDirection:'column', 
      alignItems:'flex-start', 
      'h2': {
        lineHeight:'28px'
      }
    }
  }

  const handleDragEnd = (event) => {
    const {active, over} = event;
    //console.log('handleDragEnd active', active)
    //console.log('handleDragEnd over', over)

    const activeContainer =  event.active.data.current.sortable.containerId;
    const overContainer =  event.over.data.current.sortable.containerId;

    const activeIndex =  event.active.data.current.sortable.index;
    const overIndex =  event.over.data.current.sortable.index;

    const source_part_id = story.parts[activeContainer].id
    const dest_part_id = story.parts[overContainer].id
    const moving_shot_id = active.id  // columns[activeContainer].items[source.index].id /// columns[activeContainer].items[oldIndex].id
    let preceding_shot_id = '000'
    
    if (overIndex > 0) {
        const items = story.parts[overContainer].order.map(shot_id => shots.filter(shot => shot.id===shot_id)[0]).filter(s => s!==undefined)
        preceding_shot_id = items[overIndex - 1].id  
    }

    if ((activeIndex !== overIndex) || (activeContainer !== overContainer)) {
      const payload = {source_part_id: source_part_id, dest_part_id: dest_part_id, moving_shot_id: moving_shot_id, preceding_shot_id: preceding_shot_id}
      dispatch({ type: "MOVE_STORY_SHOT", payload: payload });
    }
    if (false) {
      if (active.id !== over.id) {
          const oldIndex = columns[activeContainer].items.findIndex(({id}) => id === active.id);
          const newIndex = columns[overContainer].items.findIndex(({id}) => id === over.id);
          const result = arrayMove(columns[overContainer].items, oldIndex, newIndex);

          //console.log('oldindex', columns[activeContainer].items)
          //console.log('newindex', result)
          if(activeContainer !== overContainer) {
            const sourceColumn = columns[activeContainer];
            const destColumn = columns[overContainer];
            const sourceItems = [...columns[activeContainer].items];
            const destItems = [...columns[overContainer].items];
            console.log('destColumn', sourceColumn)
            console.log('destColumn', destColumn);
            console.log('sourceItems',sourceItems);
            console.log('destItems', destItems);
            const [removed] = sourceItems.splice(oldIndex, 1);
            destItems.splice(newIndex, 0, removed)

            // setColumns({
            //   ...columns, 
            //   [activeContainer]: {
            //     ...sourceColumn,
            //     items: sourceItems
            //   },
            //   [overContainer] : {
            //     ...destColumn,
            //     items: destItems
            //   }
        
            // })

            return true
          }

          // setColumns(columns => ({
          //   ...columns,
          //   [activeContainer]: {
          //     ...columns[activeContainer],
          //     items: result
          //   }
          // }))

          return true
      }
    } 
  }
 

  const next_shot_index = props.shots.length + 1

  const shots = props.shots.filter(s => s!==undefined)
  function repeatString(string, n) {
    let repeatedString = "";
    while (n > 0) {
      repeatedString += string;
      n--;
    }
    return repeatedString;
  }

  //  we display the inspiration images, or the lastest take of that mode is on, and there is one for the shot
  let sortProps = {...props}
  
  const card_group = <Grid container spacing={{ xs: 2 }}>
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      sx={{ touchAction:'none' }}
    >
        { Object.entries(story.parts).map(([key, part]) => (
          <SortableContext
            key={key}
            items={ part.order.map(shot_id => shots.filter(shot => shot.id==shot_id)[0]).filter(s => s!==undefined) }   
            strategy={rectSortingStrategy}
            id={key}
            sx={{ touchAction:'none' }}
          >
            {!state.app.isLoading && part.order.map(shot_id => shots.filter(shot => shot.id==shot_id)[0]).filter(s => s!==undefined).map((item, key) => (
              <SortableItem key={key} data={item} setSelected={() => { setIsSelected(item.id); props.setMoveToShot(item.id) }} {...sortProps} selected={isSelected == item.id} sx={ gridItemStyles } /*onPlayChange={ handlePlay }*/ />
            ))}
          </SortableContext>
        ))}
        </DndContext>
        {/*!props.minimal && <Grid item xs={6} sm={4} md={3} lg={2}>
          <Item onClick={() => props.addShot(props.part_id, next_shot_index + ":")} className="add-item" sx={ addItemStyles }>
            <AddIcon sx={{ alignSelf:'center', marginBottom:'.5em '}} />
            <Typography variant="body2">Add Shot</Typography>
          </Item>
        </Grid>*/}
      </Grid>

  return (
    <div>
      {props.title && <Box sx={ titleStyles }>
        <Typography variant="h2" sx={{ marginBottom:"10px" }}> {props.title} 
        {/*!expanded && <i className="angle right icon" onClick={expand}></i>*/}
        </Typography>
        <Box>{(shots.length <= 2) ? "Describe your story with shots." : shots.length + " shots ~" + Math.floor(shots.length * 2.5) + " sec"}</Box>
        <Box sx={{ [theme.breakpoints.down('md')]: { display:'none' } }}></Box>
      </Box>}
     {expanded && card_group}
    </div>
  );
}

export default ShotGroup;


