import { Box, Button, FormControl, Input, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import firebase, { analytics } from "../utils/firebase.js";

import AppContext from "../utils/app-context";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmReplacementDialog from "../components/confirm-replacement-dialog";
// Form
import Grid from "@mui/material/Unstable_Grid2";
import Item from "@mui/material/Unstable_Grid2";
import Loading from "./loading.js";
import StoryPartForm from "../components/story-part-form";
import { formatDate } from "../utils/utility.js";
import { nanoid } from "nanoid";
import theme from "../styles/theme";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

function NewProjectSelector() {
  const [showSuggestionButton, setShowSuggestionButton] = React.useState(false);
  const { state, dispatch } = useContext(AppContext);

  const story = state.stories.activeStory; //state.story //state.stories.activeStory
  const stories = state.stories.storyList;

  //  default titles for three parts ... currently suppressed for simplicity of onboarding
  const part1_init = state.story.parts[0].title;
  const part2_init = state.story.parts[1].title;
  const part3_init = state.story.parts[2].title;
  const history = useHistory();
  const [part1, setPart1] = useState(part1_init);
  const [part2, setPart2] = useState(part2_init);
  const [part3, setPart3] = useState(part3_init);

  // default imported video
  //const [url, setUrl] = useState("https://www.youtube.com/watch?v=hKWMrSGO6RQ");
  //const [importUrl, setImportUrl] = useState("https://www.youtube.com/watch?v=hKWMrSGO6RQ");  // 2014 pasta vine
  const [importUrl, setImportUrl] = useState("https://www.youtube.com/watch?v=0nXB-Bgx1eM"); // travel thumnails
  //const [importUrl, setImportUrl] = useState("https://www.youtube.com/watch?v=Ktb44YLL8Nw");  // take on me ... fading lyrics dont trigger, needs other threshold

  const emptyStory = (init_shot_id) => {
    const created = new Date();
    return {
      parts: [{ title: "Storyboard", order: [init_shot_id] }],
      title: formatDate(created, "title"),
      last_cut: null,
      portal_id: nanoid(12),
      created_at: created.getTime(),
      deleted_at: null, 
      shots: [
        {
          header: "Shot 1",
          description: "HERO starts JOURNEY",
          meta: null,
          image: "https://storage.googleapis.com/v0zebra.appspot.com/resource/shot_type/wide.png",
          id: init_shot_id,
          deleted_at: null,
        },
      ],
    };
  };

  const [urlValid, setUrlValid] = React.useState(true);

  const handleContinueParts = () => {
    analytics.logEvent("BUTTON_CONTINUE_AFTER_PARTS");
    const parts = [
      { title: part1, order: story.parts[0].order },
      { title: part2, order: story.parts[1].order },
      { title: part3, order: story.parts[2].order },
    ];
    dispatch({ type: "UPDATE_STORY_PARTS", payload: parts });
    history.push("/p/" + portal_id);
  };

  const handleContinueEmpty = () => {
    const id = uuidv4();
    const story = emptyStory(id);
    dispatch({ type: "NEW_ACTIVE_STORY", payload: { newStory: story } });
    history.push("/p/" + story.portal_id);
  };

  const handleContinueImport = () => {
    const parts = [
      { title: "Storyboard", order: [] },
      { title: "B-Roll", order: [] },
    ];
    dispatch({ type: "UPDATE_STORY_PARTS", payload: parts });
    history.push("/p/" + portal_id);
  };

  const CancelNewStoryAndContinue = () => {
    history.push("/storyboards");
  };

  function runTest() {
    alert("would test");
  }

  function renderPartText() {
    alert(`Part1 is ${part1}, Part2 is ${part2}, Part3 is ${part3}`);
    setPart1("a");
    setPart2("b");
    setPart3("c");
  }

  function handleUrlFormChange(e) {
    const url = e.target.value;
    const valid_start = "www.youtube.com/watch?v=";
    const valid_short_code_length = 11;
    if (url.includes(valid_start) && url.substr(url.lastIndexOf(valid_start) + valid_start.length).length === valid_short_code_length) {
      setUrlValid(true);
    } else {
      setUrlValid(false);
      //console.log("bad short code", url.substr(url.lastIndexOf(valid_start) + valid_start.length))
    }
    setImportUrl(url);
  }

  function importStoryboard() {
    if (!urlValid) return;

    const storyRef = state.stories.activeStory.ref;
    if (storyRef) {
      const created = new Date();
      const date =
        created.getHours() +
        ":" +
        (created.getMinutes() < 10 ? "0" + created.getMinutes() : created.getMinutes()) +
        " " +
        created.toLocaleString("default", { month: "short" }) +
        " " +
        created.getDate() +
        ", `" +
        created.getFullYear().toString().slice(2);
      const import_req_id = uuidv4(); // '1234' //
      const import_request = {
        type: "storyboard",
        url: importUrl,
        //"https://www.youtube.com/watch?v=aMlt1I-W5EM",
        // amknI7qr25g W86cTIoMv2U",
        // aMlt1I-W5EM  7 sec works
        // https://www.youtube.com/watch?v=qBNmY7S0BG8  12sec stop motion breaks
        // QpiGPLUCC3w
        // https://www.youtube.com/watch?v=adLGHcj_fmA   bruno
        //location: "the/user/the/story",
        title: date.toString(),
        created_at: created.getTime(),
        completed_at: null,
        deleted_at: null, 
      };

      const importRequestRef = storyRef.collection("imports").doc(import_req_id);
      importRequestRef
        .set(import_request)
        .then(() => {
          dispatch({ type: "START_IMPORT", payload: {} });
          handleContinueImport();
        })
        .catch((error) => {
          console.log("Error creating import request:", import_request);
        });
      console.log("tried_import:", import_request);
    } else {
      console.log("no STORY, failed to test import request");
    }
  }

  function SuggestionButton() {
    return <ConfirmReplacementDialog name="Get Suggestion" title="Text replacement" desc="Replace current text with a randomized story idea?" doIfConfirmed={renderPartText} />;
  }

  const alternate_form = <StoryPartForm addTask={renderPartText} init={importUrl} text={importUrl} set={setImportUrl} />;

  const alternate_buttons = (
    <div className="action_advance">
      <button type="button" className="btn" onClick={handleContinueEmpty}>
        {" "}
        Start empty{" "}
      </button>
      {"  "}
      <button type="button" className="btn__primary" onClick={handleContinueImport}>
        {" "}
        Create storyboard
      </button>
    </div>
  );

  const buttons_suggest_video = (
    <div className="action_advance">
      <Button color="primary" onClick={handleContinueEmpty}>
        Empty Storyboard
      </Button>
      {urlValid ? (
        <Button content="Create Storyboard" labelPosition="right" icon="checkmark" onClick={importStoryboard} positive />
      ) : (
        <Button content="Invalid" labelPosition="right" icon="close" onClick={handleContinueEmpty} negative />
      )}
    </div>
  );

  const create_board_from_video = (
    <div>
      <br />
      <div className="suggestion">
        <Typography variant="h3">Choose any video as a starting point.</Typography>
      </div>
      <br />
      <FormControl>
        <Input
          size="huge"
          id="form-input-youtube-url"
          control={Input}
          label="Youtube URL"
          value={importUrl}
          onChange={handleUrlFormChange}
          error={
            urlValid
              ? false
              : {
                  content: "Please enter a valid url for a video.",
                  pointing: "above",
                }
          }
        />
      </FormControl>
    </div>
  );

  return (
    <div className="app_container">
      <Box component="header" sx={{ display: "flex", padding: "0 1em", justifyContent: "space-between", borderBottom: "1px solid " + theme.palette.primary.main }}>
        <Typography variant="h2">New storyboard</Typography>
        {/*state.activeStory && Object.keys(state.activeStory).length && */}
        {stories.length > 1 && <CloseIcon onClick={CancelNewStoryAndContinue} sx={{ alignSelf: "center" }} />}
      </Box>
      <Box sx={{ textAlign: "center", height: "80vh", display: "flex" }}>
        <Box sx={{ maxWidth: "450px", margin: "0 auto", alignSelf: "center" }}>
          <div>
            <img src={"logo192.png"} alt="" width={98} />
          </div>
          <Typography variant="brand" sx={{ marginBottom: "15px" }}>
            10zebra
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: "30px" }}>
            Do you want to start a new storyboard?
          </Typography>
          <Button variant="contained" size="large" onClick={handleContinueEmpty}>
            New Storyboard
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default NewProjectSelector;
