
// note:  once deployed, some of this may be accomplished from document.URL ... but not the localhost scenario...

const okvHostConfig = {
    "app_url": "https://okapi-webdev.web.app",
    "home_url": "https:okapivideo.com",
    "storage_api": "https://storage.googleapis.com/v0zebra-dev.appspot.com", 
    "env": "okv"
};

const prod10zHostConfig = {
    "app_url": "https://10zebra.app",
    "home_url": "https://10zebra.com",  
    "storage_api": "https://storage.googleapis.com/v0zebra.appspot.com",
    "env": "prod"
};
const dev10zHostConfig = {
    "app_url": "https://10zebra.dev",
    "home_url": "https://10zebra.com",
    "storage_api": "https://storage.googleapis.com/v0zebra-dev.appspot.com",
    "env": "dev"
};
const rnbwHostConfig = {
    "app_url": "https://rnbw.art",
    "home_url": "https://rnbw.art",
    "storage_api": "https://storage.googleapis.com/v0zebra-dev.appspot.com",
    "env": "rnbw"
};
const aiModels = new Map();

if(process.env.REACT_APP_FIREBASE_ENV != 'prod') {
  // display name in app drop down --> fields stored in backend: method_name,method,engine 
    // logic:  user might use method name as a display field for what it is, informative simple uuid
    //         backend tools may use engine name to run it appropriately - generally model type
    //         method can be used to split different types - e.g. different task on similar engine
    
    // name, key, method_name, method, engine, desc
  // aiModels.set('vid2vid-v0', {name:'Video to Video Zero', method_name:'vid2vid-v0', method:'vid2vid', engine:'cerspense/zeroscope_v2_576w', desc:'A video to video model'});
  // aiModels.set('vid2vid-2', {name:'Video to Video Haiper', method_name:'haiper-video', method:'vid2vid', engine:'haiper-v2', desc:'Video to video with haiper'});

  aiModels.set('comfy-FFAutoCine-v0', {name:'1st Frame AutoCine', method_name:'comfy-FFAutoCine-v0', method:'vid2vid', engine:'comfy-FFAutoCine-v0', desc:'Animate the first frame of your video.'});
  aiModels.set('haiper-image', {name:'1st Frame Haiper', method_name:'haiper-image', method:'vid2vid', engine:'haiper-v2', desc:'Animate the first frame of your video.'});
  // aiModels.set('haiper-image-last', {name:'Last Frame Haiper', method_name:'haiper-image-last', method:'vid2vid', engine:'haiper-v2', desc:'Animate from the last frame of your video.'});

  // aiModels.set('im2im', {name:'IM to IM', method_name:'im2im-v4', method:'im2im', engine:'stabilityai/stable-diffusion-2-depth', desc:'A IM to IM model'});
  // aiModels.set('text2vid-v0', {name:'Zero', method_name:'text2vid-v0', method:'text2vid', engine:'cerspense/zeroscope_v2_576w', desc:'A text to video model'});
  aiModels.set('haiper-text', {name:'Haiper', method_name:'haiper-text', method:'text2vid', engine:'haiper-v2', desc:'text to video with haiper'});
  aiModels.set('text2vid-flyper', {name:'Flyper', method_name:'text2vid-flyper', method:'text2vid', engine:'black-forest-labs/flux-pro', desc:'Flux images to haiper videos'});
  aiModels.set('text2vid-flenburns', {name:'Mr. FlenBurns', method_name:'text2vid-flenburns', method:'text2vid', engine:'black-forest-labs/flux-pro', desc:'Flux images kenburnsed'});


} else {
  // PROD
  // name, key, method_name, method, engine, desc
  // aiModels.set('vid2vid-v0', {name:'Video to Video Zero', method_name:'vid2vid-v0', method:'vid2vid', engine:'cerspense/zeroscope_v2_576w', desc:'A video to video model'});
  // aiModels.set('vid2vid-2', {name:'Video to Video Haiper', method_name:'haiper-video', method:'vid2vid', engine:'haiper-v2', desc:'Video to video with haiper'});

  aiModels.set('comfy-FFAutoCine-v0', {name:'1st Frame AutoCine', method_name:'comfy-FFAutoCine-v0', method:'im2vid', engine:'comfy-FFAutoCine-v0', desc:'Animate the first frame of your video.'});
  aiModels.set('haiper-image', {name:'1st Frame Haiper', method_name:'haiper-image', method:'im2vid', engine:'haiper-v2', desc:'Animate the first frame of your video.'});
  // aiModels.set('haiper-image-last', {name:'Last Frame Haiper', method_name:'haiper-image-last', method:'im2vid', engine:'haiper-v2', desc:'Animate from the last frame of your video.'});


  // aiModels.set('im2im', {name:'IM to IM', method_name:'im2im-v4', method:'im2im', engine:'stabilityai/stable-diffusion-2-depth', desc:'A IM to IM model'});
  // aiModels.set('text2vid-v0', {name:'Text to Video Zero', method_name:'text2vid-v0', method:'text2vid', engine:'cerspense/zeroscope_v2_576w', desc:'A text to video model'});
  aiModels.set('haiper-text', {name:'Haiper', method_name:'haiper-text', method:'text2vid', engine:'haiper-v2', desc:'text to video with haiper'});
  aiModels.set('text2vid-flyper', {name:'Flyper', method_name:'text2vid-flyper', method:'text2vid', engine:'black-forest-labs/flux-pro', desc:'Flux images to haiper videos'});
  aiModels.set('text2vid-flenburns', {name:'Mr. FlenBurns', method_name:'text2vid-flenburns', method:'text2vid', engine:'black-forest-labs/flux-pro', desc:'Flux images kenburnsed'});

}

let hostConfig = {}

switch(process.env.REACT_APP_FLAVOR) {
  case 'okv':
    hostConfig = okvHostConfig;
    break;
  case '10z':
    if (process.env.REACT_APP_FIREBASE_ENV === 'prod'){
        hostConfig = prod10zHostConfig;
    } else {
        hostConfig = dev10zHostConfig;
    }
    break;
  case 'rnbw':
    hostConfig = rnbwHostConfig;
    break;
  default:
    hostConfig = dev10zHostConfig;
}

hostConfig.aiModels = aiModels;


export default hostConfig;