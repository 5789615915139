import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material'
import theme from '../styles/theme'


function EditableField(props) {

  const [cursorPosition, setCursorPosition] = useState(props.init.length) // this state may have to exist outside this field, so its not reinit on rerender .. ?
  const runAfterUpdate = useRunAfterUpdate();
  const [text, setText] = useState(props.init);
  //setText(props.init);


  const handleChange2 = e => {
    // this is a draft of updating 
    // derived from https://gist.github.com/gondar00/0f10d6e9fee26071cf0d8503d596cb3d
    const input = e.target;
    const text = input.value;
    const cursor = input.selectionStart;
    props.setter(props.shot_id, text);

    runAfterUpdate(() => {
      console.log("input.selectionStart:  ", input.selectionStart)
      input.selectionStart = cursorPosition;
      input.selectionEnd = cursorPosition;
      console.log("after update, trying to return cursor to ", cursorPosition)
    });
  };

	function handleBlur(e) {
    setText(e.target.value)
    // immediately send a change to the reducer ... should this be 
    // cached and delayed for groups of changes?
    props.setter(props.shot_id, e.target.value);
  }

  function setValue(e) {
    setText(e.target.value)
  }
  
  function handleSubmit(e) {
    e.preventDefault();
    props.setter(props.shot_id, e.target.value);
  }

  function handleFocus(e) {
    console.log("focus", cursorPosition, e.target.selectionStart)
  }

  useEffect(() => {
         setText(props.init)
  }, [props.init]);

	return (

		<form> 
        <Box>
            <textarea 
              value={text}
              onChange={setValue}
              className={props.textClassName}
              id={"desc_field" + props.shot_id}
              name="Text1"
              onBlur={handleBlur}
              onFocus={handleFocus}
              rows={props.num_rows}
              maxLength={props.max_length}
            >
            </textarea>
        </Box>
    </form>
		);
}

function useRunAfterUpdate() {
  const afterPaintRef = React.useRef(null);
  React.useLayoutEffect(() => {
    if (afterPaintRef.current) {
      afterPaintRef.current();
      afterPaintRef.current = null;
    }
  });
  const runAfterUpdate = fn => (afterPaintRef.current = fn);
  return runAfterUpdate;
}

export default EditableField;